import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

import { connect } from 'react-redux';
import * as actions from '../../../reducers/actions';
import { API_BASE_URL, consol } from "../../../config";
import apiClient from 'src/components/apiClient';
import {
    CButton, CSpinner,
    CModal,
    CModalBody,
    CModalFooter,

} from '@coreui/react'
import { useTranslation } from "react-i18next";
//import { cibToptal } from '@coreui/icons';

const Basket = (props, state) => {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false)
    const [error, setError] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [visible, setVisible] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [basketItems, setBasketItems] = useState([])
    //const [sessionsIndex, setSessionsIndex] = useState(0)
    const [sessions, setSessions] = useState([])
    const [trainings, setTrainings] = useState([])
    const [item, setItem] = useState(null)
    const [itemType, setItemType] = useState('')
    const [total, setTotal] = useState(0)

    useEffect(() => {
        console.log('in use effect 1');
        if (localStorage.getItem('auth')) {
            console.log('in use effect 2');

             apiClient.get("/member-api/cart/getCartItems")
                .then((response) => {
                    console.log('in use effect 3');
                    console.log("user carttt");
                    console.log(response);
                    if (response.data) {
                        // setBasketItems(response.data.cart);
                        getTotal(response.data.trainings , response.data.sessions)
                        if (response.data.trainings) {
                            setTrainings(response.data.trainings);
                            // setSessionsIndex(response.data.trainings.length+1)
                        }
                        if (response.data.sessions) setSessions(response.data.sessions)
                        setLoaded(true);
                  

                    } else {
                        setLoaded(true);
                    }
                })
                .catch((response) => {
                    console.log(response);
                    setError(true)
                });
        }

    }, []);

    const getTotal = (t , s) => {
        var total = 0;
        if (t) {
            for (var i = 0; i < t.length; i++) {
                total += parseInt(t[i].price);
            }
        }

        if (s) {
            for (var i = 0; i < s.length; i++) {
                total += parseInt(s[i].price);
            }
        }

        setTotal(total);
    }
    const removeItemX = () => {
        console.log('removing: ', item , itemType);
        setSubmitted(true);
        const formData = new FormData();
        let cart = [];
        cart = basketItems;
        console.log('111:', cart);
        let cartNew = [];
        cartNew = cart.toSpliced(index, 1);
        console.log('222:', cartNew);
        //formData.append("userId", localStorage.getItem('id'));
        formData.append("cart", JSON.stringify(cartNew));
        console.log('send: ', formData.get('cart'));
        axios.post(`${API_BASE_URL}/members/updateCart`, formData)
            .then((response) => {
                console.log(response);
                if (response.data.status == 'ok') {
                    setSubmitted(false);
                    setBasketItems(response.data.cart)
                    console.log('okk :', basketItems);
                    setVisible(false);

                } else {
                    setSubmitted(false);
                    alert('Error !');
                }
            })
            .catch((response) => {
                console.log(response);
                setSubmitted(false);
                alert('Error !');

            });
    }

    const removeItem = () => {
        if (localStorage.getItem('auth')) {
            setSubmitted(true);
            props.removeFromBasket(item, itemType).then(success =>
              {
                console.log('success ? '  , success)
                if (!success) {
                    //setBasketItems(response.data.cart)
                    setSubmitted(false);
                    alert('Error !');
                } else {
                    setSubmitted(false);
                 if(itemType=='training'){
                    const updatedItems = trainings.filter(el => el.id !== item.id);
                    setTrainings(updatedItems)
                    getTotal(updatedItems , sessions)
                } 
                if(itemType=='session'){
                    const updatedItems = sessions.filter(el => el.id !== item.id);
                    setSessions(updatedItems)
                    getTotal(trainings , updatedItems)
                }
                       console.log('okk :', basketItems);
                      
                    setVisible(false);
                
                }  
              }).catch(() => {
                console.log('in catch');
                setSubmitted(false);
                alert('Error !');
              });
      
              
          }
    }

    const emptyCart = () => {
        if (localStorage.getItem('auth')) {
            setSubmitted(true);
            props.emptyBasket(item, itemType).then(success =>
              {
                console.log('success ? '  , success)
                if (!success) {
                    //setBasketItems(response.data.cart)
                    setSubmitted(false);
                    alert('Error !');
                } else {
                    setSubmitted(false);
                    setTrainings([]);
                    setSessions([]);
                    setTotal(0);

                       console.log('okk :', basketItems);
                      
                    setVisible(false);
                
                }  
              }).catch(() => {
                console.log('in catch');
                setSubmitted(false);
                alert('Error !');
              });
      
              
          }
    }

    if (error) {
        return (
            <div className="container">
                <main className="l-main">
                    <div className="l-section l-section--archive">
                        <div>Erreur : {error}</div>
                    </div>
                </main>
            </div>
        );
    } else if (!loaded) {
        return (
            <div className="container">
                <main className="l-main">
                    <div className="l-section l-section--archive">
                        <div className='loadingMain'>
                            <CSpinner color="default" size="sm" />
                        </div>
                    </div>
                </main>
            </div>
        );
    } else {
        return (
            <>
                {trainings.length + sessions.length > 0 ?
                    <div className="w_commercial_alternating_cards cc_text_group_7" style={{ "marginBottom": "10%" }}>
                        <h2 className=" text-center heading">{t("basket")}</h2>
                        <div className="basket-item" style={{ background: '#26bbcd42' }}>
                            <div className="row">
                                <div className="col-md-9 coll">
                                    <h3 >{t("title")}</h3>
                                </div>
                                <div className="col-md-2 coll">
                                    <h3 >{t("price")}</h3>
                                </div>
                            </div>

                        </div>
                        {trainings && trainings.map((item, index) => {
                            return (
                                <div className="basket-item" key={index} style={index % 2 == 0 ? { background: '#26bbcd1a' } : { background: '#26bbcd26' }}>
                                    <div className="row">

                                        <div className="col-md-9 coll">
                                            <Link to={`/trainings/${item.id}`}>   <h4 style={{ display: 'inline-block', textTransform: 'capitalize' }}>{index + 1}. {item.title}</h4>
                                            </Link> </div>
                                        <div className="col-md-2 coll">
                                            <h4 style={{ display: 'inline-block' }}> $ {item.price}</h4>

                                        </div>
                                        <div className="col-md-1"><span className='deleteFromCart' onClick={() => { setEmpty(false); setVisible(!visible); setItem(item) , setItemType('training') }} aria-hidden="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" /> <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" /> </svg>
                                        </span></div>
                                    </div>

                                </div>
                            );
                        })}

                        {sessions && sessions.map((item, index) => {
                            return (
                                <div className="basket-item" key={index} style={(index + trainings.length) % 2 == 0 ? { background: '#26bbcd0f' } : { background: '#26bbcd26' }}>
                                    <div className="row">
                                        <div className="col-md-9 coll">
                                            <Link to={`/trainings/${item.training.id}`}>
                                                <h4 style={{ display: 'inline-block', textTransform: 'capitalize' }}>{index + trainings.length + 1}. {item.title}</h4>
                                                <h6 style={{ display: 'inline-block' }}> &nbsp; &nbsp;   Session {t("from")} {item.training.title}
                                                </h6>
                                            </Link>
                                        </div>
                                        <div className="col-md-2 coll">
                                            <h4 style={{ display: 'inline-block' }}> $ {item.price}</h4>

                                        </div>
                                        <div className="col-md-1"><span className='deleteFromCart' onClick={() => { setEmpty(false); setVisible(!visible);  setItem(item) , setItemType('session')  }} aria-hidden="false">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" /> <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" /> </svg>
                                        </span></div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="basket-item" style={{ background: '#26bbcd42' }}>
                            <div className="row">
                                <div className="col-md-9 coll">
                                    <h3 >Total</h3>
                                </div>
                                <div className="col-md-2 coll">
                                    <h3 >$ {total}</h3>
                                </div>
                            </div>

                        </div>

                        {/* <div className="basket-item" style={{background:'#26bbcd42'}}>
            <div className="col-md-3 offset-md-8 text-center">
                    <h4>Total $ {total()} </h4>
                </div>
               
                    </div> */}
                       

                        <br /> <br /><div className="row">
                            <div className="col-md-3  text-center ">
                                <Link onClick={() => { setEmpty(true); setVisible(!visible); }} className="removeIconCart btn_black w-button btn-block" style={{ borderRadius: '5px', fontSize: '22px' , background:'#e20606' , borderColor:'#e20606' }}> {t("Empty_your_cart")}
                                    &nbsp; <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-trash" viewBox="0 0 16 16"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" /> <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" /> </svg>

                                </Link>
                            </div>
                            <div className="col-md-9 text-center">
                                <Link to="/checkout" className="btn_black w-button btn-block checkoutButton" > {t("Proceed_to_checkout")}</Link>
                            </div>
                        </div>

                        <CModal visible={visible && !empty} onDismiss={() => setVisible(false)}>

                            <CModalBody>{t("etes_vous_sur")}</CModalBody>
                            <CModalFooter>
                                <CButton color="secondary" onClick={() => setVisible(false)}>
                                    {t("btn_cancel")}
                                </CButton>
                                {!submitted && <CButton color="danger" onClick={() => removeItem()}>{t("btn_suppr")} </CButton>}
                                {submitted && <CButton color="danger" >{t("btn_suppr")} <CSpinner color="default" size="sm" /> </CButton>}
                            </CModalFooter>
                        </CModal>
                        
                         <CModal visible={visible && empty} onDismiss={() => setVisible(false)}>

                            <CModalBody>{t("Are_you_sure_empty_cart")} ?</CModalBody>
                            <CModalFooter>
                                <CButton color="secondary" onClick={() => setVisible(false)}>
                                    {t("btn_cancel")}
                                </CButton>
                                {!submitted && <CButton color="danger" onClick={() => emptyCart()}>{t("Empty")} </CButton>}
                                {submitted && <CButton color="danger" >{t("Empty")} <CSpinner color="default" size="sm" /> </CButton>}
                            </CModalFooter>
                        </CModal>

                    </div>
                    :
                    <div className="w_commercial_alternating_cards cc_text_group_7" style={{ "marginBottom": "10%" }}>
                        <h2 className=" text-center heading">{t("basket")}</h2>
                        <div className="basket-item" style={{ background: '#26bbcd42' }}>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h3 >{t("No_items_yet")} !</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                }

            </>
        )
    }
}
const mapStateToProps = (state) => {
    console.log(state.basket.basketItems)
  
    // setErrors(state.auth.error);
    return {
        // errorMessage:state.auth.error,
        //  authenticated:state.auth.authenticated,
        basketItems: state.basket.basketItems

    };
}
export default connect(mapStateToProps, actions)(Basket)
