import React from 'react'
import { useTranslation } from "react-i18next";
const CertificatsMaster = () => {
    const { t } = useTranslation();
    return (
        <>
              <section className="presentation_page" >
   <strong>{t("CertificatsMaster_16")}</strong>
   <p>{t("CertificatsMaster_1")}</p>
    <strong>{t("CertificatsMaster_2")}</strong>
    <p>{t("CertificatsMaster_3")}</p>
   <strong>{t("CertificatsMaster_4")}</strong>
 <p>{t("CertificatsMaster_5")}</p>
  <strong>{t("CertificatsMaster_6")}</strong>
  <p>{t("CertificatsMaster_7")}</p>
  <strong>{t("CertificatsMaster_8")}</strong>
  <ul>
    <li><strong>{t("CertificatsMaster_9")}</strong>{t("CertificatsMaster_10")}</li>
    <li><strong>{t("CertificatsMaster_11")}</strong>{t("CertificatsMaster_12")}</li>
    <li><strong>{t("CertificatsMaster_13")}</strong>{t("CertificatsMaster_14")}</li>
  </ul>

<p>{t("CertificatsMaster_15")}</p>


    
    </section>
        </>
    )
}

export default CertificatsMaster
