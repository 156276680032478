import React from 'react'
import { useTranslation } from "react-i18next";
const PhilosophyGMTC = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="w_commercial_alternated_group cc_group1" >
        <div className="w_commercial_alternating_cards cc_text_group_7" >
            <div className="w-layout-grid " id="w-node-e8bf02f74c29-3a368f7a">
                {/* <img src={"/assets/images/gdtc-tunisia-training-philosophy.jpeg"} id="w-node-e8bf02f74c29-3a368f7a"/> */}
                <div className="w_commercial_alternating_cards cc_text_group_1" id="w-node-e8bf02f74c29-3a368f7a">
                    <h2 className="h2_alternating_card" data-gsap-animate="true">{t("philosophyGMTC_1")}</h2>
                    <p>{t("philosophyGMTC_2")}</p>
                    <strong>{t("philosophyGMTC_3")}</strong>
                    <p>{t("philosophyGMTC_4")}</p>
                    <strong>{t("philosophyGMTC_5")}</strong>
                    <ul>
                    <li><strong>{t("philosophyGMTC_6")}</strong>{t("philosophyGMTC_7")}</li>
                    <li><strong>{t("philosophyGMTC_8")}</strong>{t("philosophyGMTC_9")}</li>
                    <li><strong>{t("philosophyGMTC_10")}</strong>{t("philosophyGMTC_11")}</li>
                    </ul>
                    <strong>{t("philosophyGMTC_12")}</strong>
                    <p>{t("philosophyGMTC_13")}</p>
                    <p>{t("philosophyGMTC_14")}</p>

                </div>
            </div>
        </div>
    </section>
        </>
    )
}

export default PhilosophyGMTC
