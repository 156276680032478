import { createStore , combineReducers, applyMiddleware} from 'redux';

import authReducer from './auth_reducer';
import basketReducers from './basket_reducer';
import thunk from 'redux-thunk';
const rootReducer = combineReducers({
   
  auth: authReducer,
  basket: basketReducers,
 
  
}
);
const store = createStore(rootReducer,applyMiddleware(thunk));
export default store;