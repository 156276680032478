import React, { useEffect, useState } from "react";
import { API_BASE_URL, IMAGES_BASE_URL, MEDIA_URL } from "../../config";
import axios from 'axios'
import { connect } from 'react-redux';
import ReactHtmlParser from "react-html-parser";
import {
    CCarousel,
    CCarouselIndicators,
    CCarouselInner,
    CCarouselItem,
    CCarouselCaption,
    CCarouselControl,
    CImage
  
  } from "@coreui/react";
import TrainingCard from "./training/TrainingCard";
import CIcon from "@coreui/icons-react";


const trainer = (props) => {

    const {id} = props.match.params;
    const [trainer, setTrainer] = useState(null);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [formations, setFormations] = useState([]);

    useEffect(() => {

        const formData = new FormData();
        formData.append("id", id);
        axios.post(`${API_BASE_URL}/formateurs/profile`, formData)
          .then((res) => res.data)
          .then(
            (result) => {
              console.log(result)
              setTrainer(result);
              setIsLoaded(true);
            },
            (error) => {
              console.log(error);
              setError(error)
            }
          );
    
          axios.post(`${API_BASE_URL}/formations/${id}`)
          .then((res) => res.data)
          .then(
            (result) => {
                console.log(result);
                setFormations(result);
                setIsLoaded2(true);
            },
            (error) => {
                console.log(error);
                setError(error)
            }
          );

      }, []);


      if (error) {
        return (
          <div className="container">
            <main className="l-main">
              <div className="l-section l-section--archive">
                <div>Erreur : {error.message}</div>
              </div>
            </main>
          </div>
        );
      } else if (!isLoaded) {
        return (
          <div className="container">
            <main className="l-main">
              <div className="l-section l-section--archive">
                <div>Chargement...</div>
              </div>
            </main>
          </div>
        );
      } else {
        return (
          <div className="trainerPageWrapper" >
            <div className="row">
            <div className="col-md-3">
                <img  className="profileimg" src={MEDIA_URL+trainer.profile} />
            </div>
            <div className="col-md-9 trainerHeadInfo">
             <h2>{trainer.firstName + " " +trainer.lastName}</h2>
             <h3>{trainer.profession}</h3>
             <h3>{trainer.firstline && trainer.firstline}</h3>
             <h5><CIcon size={'5xl'} name={'cilPeople'} /> 93 students</h5>
             <h5><svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M3 18.5V5a3 3 0 0 1 3-3h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6.5A3.5 3.5 0 0 1 3 18.5zM19 20v-3H6.5a1.5 1.5 0 0 0 0 3H19zM10 4H6a1 1 0 0 0-1 1v10.337A3.486 3.486 0 0 1 6.5 15H19V4h-2v8l-3.5-2-3.5 2V4z"/> </g> </svg>
             {" "+formations.length}{formations&&(formations.length>1?" Courses":" Course")}</h5>
            </div>

            </div>
            <div className="row">
            <div className="col-md-12">
            <h2>About me</h2>
                {ReactHtmlParser(trainer.description)}
            </div>
            </div>

            { trainer.images && trainer.images.length>0 &&
              <div className="row">
            <div className="col-md-8">
              <CCarousel controls indicators>
                  {((trainer.images).split(",")).map((image,index) => {
                    return(
                        image.length>3 && 
                        <CCarouselItem key={index}>
                        <CImage className="d-block w-100"
                          key={index}
                          src={MEDIA_URL+image}
                        />
                      </CCarouselItem>                
                    )
                  })}
              </CCarousel>
              </div>
              </div>
              }
            
<br/><br/><br/>
<h2>My courses</h2>
{formations.length>0 ? formations.map((formation, index) => {
                        return (
                            <div className="col-md-4" key={index} style={{marginBottom:'20px', borderRadius: '50px'}} >
                                          <TrainingCard
                                path={formation.path}
                                title={formation.title}
                                formateur={formation.createdBy.firstName&&formation.createdBy.firstName+" "+formation.createdBy.lastName}
                                createdAt={formation.createdAt.substr(0, 10)}
                                updatedAt={formation.updatedAt.substr(0, 10)}
                                sessions={formation.sessions}
                                id={formation.id}
                                language={'English'}
                                medical={false}
                                >

                                </TrainingCard>
                            </div>
                            
                        )
                    })
                :"no course here yet"
                }
            </div>

    )
}
}
 export default trainer