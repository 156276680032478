import React from 'react';
import { useHistory } from 'react-router-dom';
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CRow,
  } from '@coreui/react'

const AlreadyLoggedIn = () => {
    const history = useHistory();

    const handleLogout = () => {
        // Clear user data and redirect to home or login page
        //localStorage.clear();
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        localStorage.removeItem("id");
        localStorage.removeItem("avatar");
        localStorage.removeItem("prevUrl");
        localStorage.removeItem("profile");
        localStorage.removeItem("typeCompte");
        history.push('/852508268754/login');
    };

    return (
   
         <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody style={{margin:'auto' , textAlign:'center'}}>
                  <CForm >
                    <h1>Login</h1>
                    <h4>You are already logged in. Please log out to switch accounts.</h4>

             <br/>
                    <CRow>
                      <CCol xs="12">
                          <button className="btn_black w-button btn-block" width="80%" onClick={handleLogout}>Logout</button>


                      </CCol>

                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>

            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
    );
};

export default AlreadyLoggedIn;
