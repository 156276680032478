import React from 'react'
import "../../assets/css/style.css";
import '../../i18n/config';
import { Provider } from 'react-redux';
import {
    Route,
    Switch,
    BrowserRouter,
    HashRouter,
} from 'react-router-dom'
import PrivateRoutes from '../hooks/PrivateRoutes';
import HomePage from './HomePage';
import Navbar from '../header/Navbar';
import Footer from '../footer/Footer';
import Vision from './presentation/Vision';
import Project from './presentation/Project';
import Philosophy from './presentation/Philosophy';
import PhilosophyGMTC from './presentation/PhilosophyGMTC';
import Formation from './presentation/Formation';
import Partners from './presentation/Partners';
import PartnersGMTC from './presentation/PartnersGMTC';
import Contact from './presentation/Contact';
import About from './presentation/About';
import AboutGMTC from './presentation/AboutGMTC';
import BecomePartner from './presentation/becomePartner';
import BecomePartnerGMTC from './presentation/becomePartnerGMTC';
import Enterprise from './presentation/Enterprise';
import Certificats from './presentation/certificats';
import CertificatsMaster from './presentation/certificatsMaster';
import Staff from './presentation/staff';
import Courses from './Courses';
import MedicalCenter from './medical';
import MedicalRegister from './member/medicalRegister';
import Event from './presentation/Event'
import EventGMTC from './presentation/EventGMTC';
import Team from './presentation/Team';
import Greeting from './presentation/Greeting';
import Login from './member/Login';
import store from "../../reducers/store"
import Register from './member/Register';
import Mentions from './presentation/Mentions';
import Declaration from './presentation/Declaration';
import ScrollToTop from '../ScrollToTop';
import Trainings from './training/Trainings';
import MedicalTrainings from './training/MedicalTrainings';
import Course from './training/Course';
import MedicalCourse from './training/MedicalCourse';
import Basket from './basket/Basket';
import Checkout from './checkout/Checkout';
import trainer from './trainer';
import takeCourse from './training/takeCourse';
import ConfirmAccount from './member/Confirm';
import ForgetPass from './member/Forget';
import FavoriteProvider from './FavoriteProvider';
import Profile from './member/profile';
import QcmTest from './training/QcmTest';

const index = () => {
    return (
        <><Provider store={store}>
            {/* <HashRouter  basename={"/test-front-548646"}>*/}
            <BrowserRouter
             basename={"/test-5436726732"}
            >
                <React.Suspense >
                    <FavoriteProvider>
                        <ScrollToTop />
                        <Navbar />
                        <Switch>
                            <Route exact path="/" component={HomePage} />

                            {/* <Route exact path="/vision" component={Vision} />
                            <Route exact path="/project" component={Project} /> */}
                            <Route exact path="/philosophy" component={Philosophy} />
                            {/* <Route exact path="/ce-courses" component={Formation} /> */}
                            {/* <Route exact path="/partners" component={Partners} /> */}
                            {/* <Route exact path="/event" component={Event} /> */}
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/about" component={About} />
                            {/* <Route exact path="/team" component={Team} /> */}
                            <Route exact path="/greeting" component={Greeting} /> {/* move to event */}
                            
                            <Route exact path="/mentions-legales" component={Mentions} />
                            <Route exact path="/declaration-confidentialite" component={Declaration} />
                            
                            <Route exact path="/become-partner" component={BecomePartner} />
                            <Route exact path="/enterprise" component={Enterprise} />
                            <Route exact path="/certificates" component={Certificats} />
                            <Route exact path="/certificates-master" component={CertificatsMaster} />
                            <Route exact path="/staff" component={Staff} />

                            <Route exact path="/GMTC-medical-center/event" component={EventGMTC} />
                            <Route exact path="/GMTC-medical-center/about" component={AboutGMTC} />
                            <Route exact path="/GMTC-medical-center/become-partner" component={BecomePartnerGMTC} />
                            <Route exact path="/GMTC-medical-center/partners" component={PartnersGMTC} />
                            <Route exact path="/GMTC-medical-center/philosophy" component={PhilosophyGMTC} />

                            <Route exact path="/login" component={Login} />
                            <Route exact path="/create-account" component={Register} />
                            <Route exact path="/courses" component={Courses} />
                            <Route exact path="/GMTC-medical-center" component={MedicalCenter} />
                            <Route exact path="/GMTC-medical-center/create-account" component={MedicalRegister} />
                            <Route exact path="/trainings/:id/:slug" component={Trainings} />
                            <Route exact path="/GMTC-medical-center/trainings/:id/:slug" component={MedicalTrainings} />
                            <Route exact path="/trainings/:id" component={Course} />
                            <Route exact path="/GMTC-medical-center/trainings/:id" component={MedicalCourse} />
                            <Route exact path="/trainer/:id/:slug" component={trainer} />
                            <Route exact path="/course/:id/:slug/:type?" component={takeCourse} />
                            <Route exact path="/confirm-account" component={ConfirmAccount} />
                            <Route exact path="/reset-password" component={ForgetPass} />

                            <PrivateRoutes
                                path="/QCM-test/:id"
                                component={QcmTest}
                                render={(props) => <Profile {...this.props} />}
                                roles={["ROLE_PARTICIPANT"]}
                            />
                            <PrivateRoutes
                                path="/basket"
                                component={Basket}
                                render={(props) => <Profile {...this.props} />}
                                roles={["ROLE_PARTICIPANT"]}
                            />
                            <PrivateRoutes
                                path="/checkout"
                                component={Checkout}
                                render={(props) => <Profile {...this.props} />}
                                roles={["ROLE_PARTICIPANT"]}
                            />
                            <PrivateRoutes
                                path="/my-profile"
                                component={Profile}
                                render={(props) => <Profile {...this.props} />}
                                roles={["ROLE_PARTICIPANT"]}
                            />
                                <PrivateRoutes
                                path="/GMTC-medical-center/my-profile"
                                component={Profile}
                                render={(props) => <Profile {...this.props} />}
                                roles={["ROLE_PARTICIPANT"]}
                            />
                        </Switch>
                        <Footer />
                    </FavoriteProvider>
                </React.Suspense>
            </BrowserRouter>
            {/* </HashRouter> */}
        </Provider>
        </>
    )
}

export default index
