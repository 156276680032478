import { API_BASE_URL } from "src/config";
import axios from 'axios'
//import { useNavigate } from 'react-router-dom';
/*
{
  localStorage.removeItem("auth");
  localStorage.removeItem("username");
  localStorage.removeItem("email");
  localStorage.removeItem("token");
  localStorage.removeItem("roles");
  localStorage.removeItem("id");
  localStorage.removeItem("avatar");
  localStorage.removeItem("prevUrl");
  localStorage.removeItem("profile");
  localStorage.removeItem("typeCompte");
 // localStorage.clear();

  // message.success('Logout Success')
};
*/ 
// LOGOUT
export const logout = async () => {
  console.log('logging outt')
    try {
        await axios.post(`${API_BASE_URL}/logout`, {}, { withCredentials: true });
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        localStorage.removeItem("id");
        localStorage.removeItem("avatar");
        localStorage.removeItem("prevUrl");
        localStorage.removeItem("profile");
        localStorage.removeItem("typeCompte");
        console.log('logging outt end')
        window.location.replace("/");
       // if (window.location.pathname !== "/") { window.location.replace("/");}
        //window.location.href = '/';
        //props.history.push("/my-profile");
        //setState(false)
    } catch (error) {
        console.error('Logout failed:', error);
    }
};

export const logout2 = async () => {
  console.log('logging outt')
    try {
        await axios.post(`${API_BASE_URL}/logout`, {}, { withCredentials: true });
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        localStorage.removeItem("id");
        localStorage.removeItem("avatar");
        localStorage.removeItem("prevUrl");
        localStorage.removeItem("profile");
        localStorage.removeItem("typeCompte");
        console.log('logging outt end')
        //window.location.replace("/");
        if (window.location.pathname !== "/") { window.location.replace("/");}
        //window.location.href = '/';
        //props.history.push("/my-profile");
        //setState(false)
    } catch (error) {
        console.error('Logout failed:', error);
    }
};

// LOGIN STATUS
export const isLogin = () => {
  if (localStorage.getItem("auth")) return true;
  //tempppp
  return false;
  //return true;
};

export const isGranted = (roles) => {
  
  var rolesUser = localStorage.getItem("roles");

  for (var i = 0; i < roles.length; i++) {
    if (rolesUser === roles[i]) {
      return true;
    }
  }
  // if(localStorage.getItem('roles')== roles) return true;
  // tempppp
  return false;
  //return true;
};
