import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { CAlert, CSpinner } from '@coreui/react'
import apiClient from 'src/components/apiClient';
import { isLogin } from "src/components/middleware/auth";
import TrainingCard from "../training/TrainingCard";
import OrderCard from './OrderCard';

const MyItems = (props) => {
    const { t } = useTranslation();
    
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [results , setResults] = useState([]);

    useEffect(() => {
     if(isLogin)
       {
         apiClient.get("/member-api/get-orders" )
           .then((result) => {
             console.log('ffff',result);
            setResults(result.data);
       
             setIsLoaded(true);
           })
           .catch((error) => {
             setIsLoaded(true);
             setError('error')
             console.log(error);
           });
 
   } else console.log('not loggged innn')
   }, []);

    if (error) {
        return (
            <div className="container">
                <main className="l-main">
                    <div className="l-section l-section--archive">
                        <div>Erreur : {error.message}</div>
                    </div>
                </main>
            </div>
        );
    } else if (!isLoaded) {
        return (
            <div className="container">
                <main className="l-main">
                    <div className="l-section l-section--archive">
                        <div className='loadingMain'>
                            <CSpinner color="default" size="sm" />
                        </div>
                    </div>
                </main>
            </div>
        );
    } else

        return (
            <>
               <div className="w_commercial_alternating_cards cc_text_group_7 coursesPageWrapper" style={{ "marginBottom": "10%",marginTop:"2"}}>
                <div className="row">
                {results.length>0 ? results.map((el, index) => {
                        return (
                            <div className="col-md-12 favPage" key={index} style={{marginBottom:'20px', borderRadius: '50px'}} >    
                         
                                          <OrderCard
                               /* path={el.demo && el.demo}
                                image={el.imageTrainings && el.imageTrainings.length>0? el.imageTrainings[0].path : ''}
                                title={el.title}
                                formateur={el.createdBy ? 
                                el.createdBy.firstName&&el.createdBy.firstName+" "+el.createdBy.lastName
                                :''}
                                
                                updatedAt={el.updatedAt}
                                sessions={el.sessions}
                                id={el.id}
                                language={'English'}
                                medical={false}
                                */
                                createdAt={el.order.createdAt}
                                id={el.order.id}
                                status = {el.order.state}
                                trainings = {el.trainings}
                                sessions = {el.sessions}
                                >
                                </OrderCard>
                            </div>
                            
                        )
                    })
                : t("no_purchased_items")
                }
                </div>
      </div>
            </>
        )
}

export default MyItems
