import React from 'react'
import { useTranslation } from "react-i18next";
const BecomePartnerGMTC = () => {
    const { t } = useTranslation();
    return (
        <>
              <section className="presentation_page" >
              <strong>{t("becomePartnerGMTC_1")}</strong>
   <p>{t("becomePartnerGMTC_2")}</p>
    <strong>{t("becomePartnerGMTC_3")}</strong>
  <ul>
    <li><strong>{t("becomePartnerGMTC_4")}</strong>{t("becomePartnerGMTC_5")}</li>
    <li><strong>{t("becomePartnerGMTC_6")}</strong>{t("becomePartnerGMTC_7")}</li>
    <li><strong>{t("becomePartnerGMTC_8")}</strong>{t("becomePartnerGMTC_9")}</li>
  </ul>

   <strong>{t("becomePartnerGMTC_10")}</strong>
   <ul>
    <li><strong>{t("becomePartnerGMTC_11")}</strong>{t("becomePartnerGMTC_12")}</li>
    <li><strong>{t("becomePartnerGMTC_13")}</strong>{t("becomePartnerGMTC_14")}</li>
  </ul>

  <strong>{t("becomePartnerGMTC_15")}</strong>
  <p>{t("becomePartnerGMTC_16")}</p>

  <p>{t("becomePartnerGMTC_17")}</p>
              </section>
              </>
    )
}

export default BecomePartnerGMTC