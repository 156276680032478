import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import axios from 'axios'
import { CCard, CCardBody, CCardFooter, CButton , CSpinner} from "@coreui/react";
import { Link } from "react-router-dom";
import TrainingCard from "./TrainingCard";
 
const MedicalTrainings = (props) => {
    const { t } = useTranslation();
    const [formations, setFormations] = useState([]);

//     const banners =[
//         {category:3,
//         path:"assets/images/home3.png",
//     alt:"gdtc banner"},
//     {category:4,
//         path:"assets/images/home3.png",
//     alt:"gdtc banner"},
//     {category:5,
//         path:"assets/images/informatique2.jpg",
//     alt:"gdtc banner"
// },
//     {category:6,
//         path:"assets/images/bannière-styliste.jpg",
//     alt:"gdtc banner"},
//     {category:7,
//         path:"assets/images/bannière-menuisier.jpg",
//     alt:"gdtc banner"},
//     {category:8,
//         path:"assets/images/bannière-jornaliste.jpg",
//     alt:"gdtc banner"},
//     {category:9,
//         path:"assets/images/bannière-enfant-menuisier.jpg",
//     alt:"gdtc banner"},
//     {category:10,
//         path:"assets/images/bannière-artisant.jpg",
//     alt:"gdtc banner"},
//     ]
   // const [Banner,setBanner] =useState("assets/images/informatique2.jpg");
  //  const [Slogan,setSlogan] =useState("");
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(true);
    const {id} = props.match.params;
    // const slogans =[
    // {category:11,text:t("scroll")},
    // {category:12,text:t("scroll")},
    // {category:13,text:t("scroll")},
    // {category:14,text:t("scroll")},
    // ]


    useEffect(() => {
        setIsLoaded(false)
        axios(`${API_BASE_URL}/trainings/${id}`)
            .then((res) => res.data)
            .then(
                (result) => {
                    console.log(result);
                    setFormations(result);
                    setIsLoaded(true);
                    console.log(result)
                },
                (error) => {
                    console.log(error);
                    setError(error)
                }
            );
            //request banner from backend
            console.log('ggggggggg');
          //console.log(banners.find(item => item.category == id));
           //setSlogan(slogans.find(item => item.category == id).text);
    }, [id]);

    if (error) {
        return (
            <div className="w_commercial_alternating_cards cc_text_group_7" style={{ "marginBottom": "10%" }}>
            <div className="row">
                {error.message}
            </div>
            </div>
        );
    }  else {
        return (
            <div className="w_commercial_alternating_cards cc_text_group_7 coursesPageWrapper" style={{ "marginBottom": "10%",marginTop:"2",marginLeft:"0px",marginRight:"0px"}}>
                <div className="row">
          {/* {Banner && <div className='fullBanner fullBanner2'><img src={Banner} alt='gdtc home banner'/></div>} */}


          <div className="s_homehero s_homeheroMedical">
            <div className="w_homeherotext">
                <div className="c_page">
                <h1 data-gsap-animate="true" className="h_xlarge">
                    {id==11 && <>
    {t("dental_slogan1")} <br />{t("dental_slogan2")}
                   </>}
                   {id==12 && <>
    {t("ophtal_slogan1")} <br />{t("ophtal_slogan2")}
                   </>}
                    </h1>
         
                    <div className="w_scrollline">
                        <div className="d_centeredtextandline">
                            <div className="sp_scroll">{t("scroll")}</div>
                            <div className="m_scrollline">
                                <div className="scrollline"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w_headerimages" style={{top:"-70px"}}>
                <div className="w-layout-grid grid-6">
                    <div className="w-layout-grid grid-8">
                        <div id="w-node-f9a4d6293485-3a368f7a" className="img_heroframe cc-f1" style={{backgroundImage:"url('/assets/images/portrait-horizontal-femme.jpg')"}}>
                            <div className=""></div>
                            <a className="link-block-6 w-inline-block"></a>
                            <div className="w_vertprojecttitle">

                            </div>
                        </div>


                    </div>
                    <div className="w_imggroup">
                        <div className="w-layout-grid grid-7">
                            <div id="w-node-8226eb520370-3a368f7a" className="img_heroframe cc-f4" style={{backgroundPosition:"50%",backgroundImage:"url('/assets/images/femme-posant.jpg')"}}>
                                <div className=""></div>
                                <a className="lb_imggrid w-inline-block"></a>
                                <div className="w_vertprojecttitle">

                                </div>
                            </div>
                            <div id="w-node-e630e9f9e3b4-3a368f7a" className="img_heroframe cc-f5" style={{backgroundImage:"url('/assets/images/lunettes-soleil-transparentes-colorees.jpg')"}}>
                                <div className=""></div>
                                <a className="link-block-5 w-inline-block"></a>
                                <div className="w_vertprojecttitle">
                                    <div className="m_projecttitle">

                                    </div>
                                </div>
                            </div>
                            <div id="w-node-35e2ed03adf0-3a368f7a" className="img_heroframe cc-f8">
                                <div className=""></div>
                                <div className="w_vertprojecttitle">
                                    <div className="m_projecttitle">

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>


{/* 
          <div className='medical_training_3banners'>
          <div className="medical_training_3banners1"><img src="assets/images/portrait-horizontal-femme.jpg" /></div>
          <div className="medical_training_3banners2"><img src="assets/images/femme-posant.jpg" /></div>
          <div className="medical_training_3banners3"><img src="assets/images/lunettes-soleil-transparentes-colorees.jpg" /></div>
        </div> */}
{/* 
          <div className="s_homehero s_homeheroMedical">
            <div className="w_headerimages">
                <div className="w-layout-grid grid-6">
                    <div className="w-layout-grid grid-8">
                        <div id="w-node-f9a4d6293485-3a368f7a" className="img_heroframe cc-f1 medical_3banners">
                            <div className=""><img src="assets/images/portrait-horizontal-femme.jpg" /></div>
                            <a className="link-block-6 w-inline-block"></a>
                            <div className="w_vertprojecttitle">

                            </div>
                        </div>


                    </div>
                    <div className="w_imggroup">
                        <div className="w-layout-grid grid-7">
                            <div id="w-node-8226eb520370-3a368f7a" className="img_heroframe cc-f4 medical_3banners">
                                <div className=""><img src="assets/images/femme-posant.jpg" /></div>
                                <a className="lb_imggrid w-inline-block"></a>
                                <div className="w_vertprojecttitle">

                                </div>
                            </div>
                            <div id="w-node-e630e9f9e3b4-3a368f7a" className="img_heroframe cc-f5 medical_3banners">
                                <div className=""><img src="assets/images/lunettes-soleil-transparentes-colorees.jpg" /></div>
                                <a className="link-block-5 w-inline-block"></a>
                                <div className="w_vertprojecttitle">
                                    <div className="m_projecttitle">

                                    </div>
                                </div>
                            </div>
                            <div id="w-node-35e2ed03adf0-3a368f7a" className="img_heroframe cc-f8 medical_3banners">
                                <div className=""></div>
                                <div className="w_vertprojecttitle">
                                    <div className="m_projecttitle">

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="medicalPageMainWrapper" >
        <section className="presentation_page_medical presentation_page_medicalNarrow" >         
{id==12 && <>
    <p>{t("ophthalmology_presentation1")}</p>
    <p>{t("ophthalmology_presentation2")}</p>
    <p>{t("ophthalmology_presentation3")}</p>
    <p>{t("ophthalmology_presentation4")}</p>
</>}

</section><br/><br/>

                </div>
   <h2 className=" text-center heading">COURSES</h2>
<div style={{paddingLeft:"6%",paddingRight:"5%"}} className="row">         

           {isLoaded && formations.length > 0 ? formations.map((formation, index) => {
                        return (
                            <div className="col-md-4" key={index} style={{ marginBottom: '20px', borderRadius: '50px' }} >
                                <TrainingCard
                                    path={formation.demo && formation.demo}
                                    image={formation.imageTrainings && formation.imageTrainings.length > 0 ? formation.imageTrainings[0].path : ''}
                                    title={formation.title}
                                    formateur={formation.createdBy ?
                                        formation.createdBy.firstName && formation.createdBy.firstName + " " + formation.createdBy.lastName
                                        : ''}
                                    createdAt={formation.createdAt}
                                    updatedAt={formation.updatedAt}
                                    sessions={formation.sessions}
                                    id={formation.id}
                                    language={'English'}
                                    medical={true}
                                >

                                </TrainingCard>
                            </div>

                        )
                    })
                        : isLoaded && formations.length < 1 ?
                            "no course here yet"
                            : <div className='loadingMain'>
                                <CSpinner color="default" size="sm" />
                            </div>
                    }

                    </div>
                </div>
            </div>
        )
    }
}

export default MedicalTrainings
