import React, { Component } from "react";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CSpinner} from '@coreui/react'
import { useTranslation } from "react-i18next";

function ConfirmAccount(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid");
  const ukey = searchParams.get("ukey");

  useEffect(() => {
    console.log("this.props");
    console.log(uid, "---", ukey);
    const formData = new FormData();
    formData.append("uid", uid);
    formData.append("ukey", ukey);

    axios
      .post(API_BASE_URL + "/members/confirm-account", formData)
      .then((res) => {
        console.log(res.data);
        setIsLoading(false);
        if (res.data.status == "1") {
          setIsConfirmed(true);
          setTimeout(() => {
            window.location.href = "/login";
          }, 3000);
        } else setIsError(true);
      })

      .catch((error) => {
        console.log("errors : ", error);
        setIsLoading(false);
        setIsError(true);
      });

  }, []);

  if (isLoading) {
    return (
     
      <div className="w_commercial_alternating_cards cc_text_group_7" style={{textAlign:"center" ,"marginBottom": "10%", "padding": "15px", "borderRadius": "4px" }}>

           <div className="loading_logo">
            <CSpinner color="default" size="l" />
              <p style={{ textAlign:"center",fontSize:"22px"}}>Chargement...</p>
            </div>
          </div>
        
    );
  } else if (isConfirmed)
    return (
      <div className="w_commercial_alternating_cards cc_text_group_7" style={{textAlign:"center" ,"marginBottom": "10%", "padding": "15px", "borderRadius": "4px" }}>
    
            <div className="loading_logo">
              <br />
              <img
                src={"assets/icons/success.png"}
                alt="success icon"
                width="100"
              /><br/><br/>
              <p style={{ color: "#1c9070" ,textAlign:"center",fontSize:"22px"}}>
              {t("account_confirmed_1")}.<br/> {t("account_confirmed_2")}!
              </p>
            </div>
          </div>
      
    );
  else
    return (
      <div className="w_commercial_alternating_cards cc_text_group_7" style={{textAlign:"center" ,"marginBottom": "10%", "padding": "15px", "borderRadius": "4px" }}>

            <div className="loading_logo">
              <br />
              <img
                src={"assets/icons/fail.png"}
                alt="fail icon"
                width="100"
              /><br/><br/>
              <p style={{ color: "#db382d" ,textAlign:"center",fontSize:"22px"}}>
              {t("account_not_confirmed")}
                <a
                  style={{ color: "#db382d" }}
                  href="mailto:contact@gdtc.ac"
                >
                  {" "}
                  contact@gdtc.ac
                </a>
              </p>
            </div>
         
      </div>
    );
}
export default ConfirmAccount;
