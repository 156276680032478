import React from 'react'
import { useTranslation } from "react-i18next";
const Enterprise = () => {
    const { t } = useTranslation();
    return (
        <>
              <section className="presentation_page" >
   {/* <strong>Introduction</strong> */}
   <p>{t("enterprise_1")}</p>
    <strong>{t("enterprise_2")}</strong>
    <p>{t("enterprise_3")}</p>
   <strong>{t("enterprise_4")}</strong>
 <p>{t("enterprise_5")}</p>
  <strong>{t("enterprise_6")}</strong>
  <ul>
    <li><strong>{t("enterprise_7")}</strong>{t("enterprise_8")}</li>
    <li><strong>{t("enterprise_9")}</strong>{t("enterprise_10")}</li>
    <li><strong>{t("enterprise_11")}</strong>{t("enterprise_12")}</li>
  </ul>
  <strong>{t("enterprise_13")}</strong>
<p>{t("enterprise_14")}</p>

    <p>{t("enterprise_15")}</p>

    
    </section>
        </>
    )
}

export default Enterprise
