//import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//import { MEDIA_URL } from "../../../config";
import Moment from "react-moment";
//import { isLogin } from "src/components/middleware/auth";
import { Link } from "react-router-dom";

const OrderCard = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
     console.log('chpppprps',props)
 }, []);
 

  return (
    <div className="orderCard">
    <div className="orderCardHeading" >
    <div className="row"> 
    <div className="col-md-4">       
      <span>{t("Order_Id")} : <strong>{props.id}</strong></span>
      </div>
    <div className="col-md-4">       
    <span>{t("Ordered_at")}  : <strong><Moment format="DD-MM-YYYY">
              {props.createdAt}
            </Moment></strong></span> 
            </div>
    <div className="col-md-4">       
    <span>{t("Status")} : <strong>{props.status}</strong></span>
    </div>
    </div>
    </div>
   <div className="orderCardBody">
   {props.trainings && props.trainings.length>0 && props.trainings.map((el, index) => {
                        return (
                            <div className="col-md-12 orderItemSingle" key={index} style={{marginBottom:'20px'}} >    
                               <Link
                               to={ props.status == 'completed' ?
                                `/course/${el.id}/${el.slug}` 
                                : '#'} 
                               >
                            <strong> {el.title}</strong>
                               </Link>                                           
                            </div>
                            
                        )
                    })
                
                }
   {props.sessions && props.sessions.length>0 && props.sessions.map((el, index) => {
                        return (
                            <div className="col-md-12 orderItemSingle" key={index} style={{marginBottom:'20px'}} >    
                               <Link
                               to={
                                props.status == 'completed' ? 
                                `/course/${el.id}/${el.training.slug}/session`
                                :'#'} 
                               >
                            <strong> {el.title}</strong>
                            <span style={{ display: 'inline-block' }}> &nbsp; 
                      (session {t("from")} {el.training.title})
                    </span> 
                               </Link>                                           
                            </div>
                            
                        )
                    })
                
                }
   </div>
    </div>
  );
};
export default OrderCard;
