import React, { useState , useEffect} from 'react';
import GeneralContext from './GeneralContext';
import { API_BASE_URL } from 'src/config';
import apiClient from './apiClient';
import axios from 'axios';

const GeneralProvider = ({ children }) => {
  const [listTrainer, setListTrainer] = useState([]);
  const [listCategory, setListCategory] = useState([]);



    useEffect(() => 
    {
console.log('in provider')
if(localStorage.getItem("auth") && localStorage.getItem("roles") 
  && (localStorage.getItem("roles")=='ROLE_ADMIN' || localStorage.getItem("roles")=='ROLE_MANAGER')
)
      apiClient(`/formateurs`)
      .then((res) => res.data)
      .then(
          (result) => {
              console.log("result: ",result)
              setListTrainer(result)
          },
          (error) => {
              console.log(error);

          }
      );
      axios.get(`${API_BASE_URL}/categories-trainings`).then((res) => {
        setListCategory(res.data)
      });
    }
    , [])

  const value = {
listTrainer,
listCategory
  };

  return (
   
    <GeneralContext.Provider value={value}>
      {children}
    </GeneralContext.Provider>
  );
};

export default GeneralProvider;
