import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, consol } from "../../../config";
import * as actions from '../../../reducers/actions';
import { CAlert, CFormCheck ,CSpinner} from '@coreui/react'
//import Cookies from 'js-cookie';

const Login = (props) => {
    const { t } = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error,setError] = useState(false);
    const [notActive,setNotActive] = useState(false);
    const [loggedInDashboard,setLoggedInDashboard] = useState(false);

    const validEmailRegex = RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    const login = async () => {
        consol('logging in')
        try {
            const res = await axios.post(`${API_BASE_URL}/members/login`, { email, password }, { withCredentials: true });
 
            console.log('login resxxxx :',res);
            if (res.data.user) {
              //  Cookies.set('token', res.data.token, { secure: true, sameSite: 'None' });
                localStorage.setItem('auth', res.data.user);
                localStorage.setItem('username', res.data.user.email);
                localStorage.setItem('firstName', res.data.user.firstName);
                localStorage.setItem('lastName', res.data.user.lastName);
                localStorage.setItem('roles', res.data.user.roles);
                //localStorage.setItem('id', res.data.user.id);
                if (res.data.user.profile && res.data.user.profile.length > 1) localStorage.setItem('profile', res.data.user.profile);
 
                props.history.push("/my-profile");
            } else if (res.data.active == false) {
                setNotActive(true);
            } else {
                setError(true);
            }
            setSubmitted(false);
        } catch (error) {
            setError(true);
            console.log('catch:', error);
            setSubmitted(false);
        }
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        setError(false);
        setNotActive(false);
        setLoggedInDashboard(false);
        console.log(email,"--",password);
        if(localStorage.getItem('auth')) {
        setLoggedInDashboard(true);
        console.log('alllr');
     } else if (validEmailRegex.test(email) && password) {
        setSubmitted(true);
     login();
            /*const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            axios
            .post(`${API_BASE_URL}/members/login`, {
                email: email,
                password: password
              })
            .then((res) => {

                console.log(res);
              if (res.data.user) {     
                  localStorage.setItem('auth', res.data.token);
                  Cookies.set('token', res.data.token, { secure: true, sameSite: 'Strict' });
                  localStorage.setItem('username', res.data.user.email);
                  localStorage.setItem('firstName', res.data.user.firstName);
                  localStorage.setItem('lastName', res.data.user.lastName);
                  localStorage.setItem('roles', res.data.user.roles);
                 localStorage.setItem('id', res.data.user.id);
                  if(res.data.user.profile && res.data.user.profile.length>1) localStorage.setItem('profile', res.data.user.profile);

                  props.history.push("/my-profile");
                
                } else  if (res.data.active == false) {
                 setNotActive(true);
                } 
                else {
                  setError(true);
                }
              setSubmitted(false);  
            })
            .catch((response) => {
                setError(true);
                console.log('catch:',response);
                setSubmitted(false);
            });*/


        }


    }
    return (
        <>
            <div className="c_full_vieport_container cc_individual_case">
                <div className="em_custom_styles w-embed">
                    <div className="s_indv_port_text_sec" >
                        <div className="w_commercial_alternating_cards cc_text_group_7" >


                            <div className="gi_rep w-dyn-item " style={{ width: "100%" }} >

                                <form onSubmit={handleSubmit} className="form-validate">

                                    <h3>{t("login")}</h3>


                                    <div className="fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100" id="login">
                                        <label className="fsLabel fsRequiredLabel"
                                            htmlFor="name">{t("email")}<span
                                                className="fsRequiredMarker">*</span> </label>


                                        <input type="email" value={email} size="55"
                                            name="name" className="fsField form-control" onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {submitted && !validEmailRegex.test(email) && (
                                            <span className="invalid-feedback error">
                                                Invalid email
                                            </span>
                                        )}

                                    </div>
                                    <div className="fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100">
                                        <label className="fsLabel fsRequiredLabel"
                                            htmlFor="password">{t("password")}<span
                                                className="fsRequiredMarker">*</span> </label>


                                        <input type="password" size="55" value={password}
                                            name="password" className="fsField form-control"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />

                                        {submitted && !password && (
                                            <span className="invalid-feedback error">
                                            {t("required")}
                                            </span>
                                        )}
                                    </div>
                                    <br />
                    { error && (
                        <CAlert color="danger" >
                        {t("incorrect_login")}
                        </CAlert>
                    )}
                    {notActive && (
                        <CAlert color="danger" >
                        {t("unconfirmed_login")}
                        </CAlert>
                    )}
                    {loggedInDashboard && (
                        <CAlert color="danger" >
                        {t("already_loggedin")}
                        </CAlert>
                    )}

                                   {!submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
                                        <input id="fsSubmitButton3395640" className="btn_black w-button btn-block"
                                            type="submit" value={t("login")} width="80%" />

                                    </div>}
                                    {submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
                                        <input disabled id="fsSubmitButton3395640" className="btn_black w-button btn-block"
                                            type="submit" value={t("login")} width="80%" /> <CSpinner style={{display:"block",margin:"auto",marginTop:"10px"}} color="default" size="sm" />

                                    </div>}
                                    <br />
                                    <Link to="/reset-password" style={{ textAlign: "center" }}>{t("forget_password")}</Link>
                                    <Link to="/create-account" style={{ textAlign: "right", float: "right" }}>{t("creer_compte_btn")}</Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state) => {
    console.log(state)
    // setErrors(state.auth.error);
    return {
        // errorMessage:state.auth.error,
        //  authenticated:state.auth.authenticated,

    };
}

export default connect(mapStateToProps, actions)(Login)


