import axios from 'axios';
import { API_BASE_URL } from 'src/config';
import { logout } from './middleware/auth';

const apiClient = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true, // Important for sending cookies
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add a response interceptor
apiClient.interceptors.response.use(
    (response) => {
        const newToken = response.headers['x-refresh-token'];
        if (newToken) {
            console.log('New token received:', newToken);
            // No need to set it in cookies as it's httpOnly
        }
        return response;
    },
    (error) => {
        console.log('Unauthorized error---', error);

        if (error.response && error.response.status === 401) {
            console.log('Unauthorized:', error.response.status);
           logout();
            // Handle unauthorized access
            // Implement your logout logic here if needed
        }
        if (error.response && error.response.status === 403) {
            console.log('Unauthorized 222:', error.response.status);
            // Handle unauthorized access
            // Implement your logout logic here if needed
        }
        return Promise.reject(error);
    }
);

export default apiClient;