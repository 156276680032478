import React from 'react';
import { Route } from 'react-router-dom';
import { isLogin } from '../middleware/auth';
import AlreadyLoggedIn from './AlreadyLoggedIn';

const PublicRoute = ({ component: Component, restricted, ...rest }) => (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={props => (
        isLogin() && restricted ? <AlreadyLoggedIn /> : <Component {...props} />
    )} />
);

export default PublicRoute;
