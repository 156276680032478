import React from 'react'
import { useTranslation } from "react-i18next";
const BecomePartner = () => {
    const { t } = useTranslation();
    return (
        <>
              <section className="presentation_page" >
              <strong>{t("becomePartner_1")}</strong>
   <p>{t("becomePartner_2")}</p>
    <strong>{t("becomePartner_3")}</strong>
  <ul>
    <li><strong>{t("becomePartner_4")}</strong>{t("becomePartner_5")}</li>
    <li><strong>{t("becomePartner_6")}</strong>{t("becomePartner_7")}</li>
    <li><strong>{t("becomePartner_8")}</strong>{t("becomePartner_9")}</li>
  </ul>

   <strong>{t("becomePartner_91")}</strong>
   <ul>
    <li><strong>{t("becomePartner_10")}</strong>{t("becomePartner_11")}</li>
    <li><strong>{t("becomePartner_12")}</strong>{t("becomePartner_13")}</li>
  </ul>

  <strong>{t("becomePartner_14")}</strong>
  <p>{t("becomePartner_15")} <a> partenaire@gdtc.ac </a> {t("becomePartner_16")}</p>

  <p>{t("becomePartner_17")}</p>
              </section>
              </>
    )
}

export default BecomePartner