import React from 'react'
import { useTranslation } from "react-i18next";
const PartnersGMTC = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="c_full_vieport_container cc_individual_case">
                <div className="s_indv_port_img">
                    <img src={"/assets/images/event.jpeg"} alt="" />
                    <div className=""></div>
                </div>
            </div>
            <div className="s_awards">
                <div className="c_page">
                    <div className="w-layout-grid grid-26">
                        <div id="w-node-907d6837e851-3a368f7a" className="d_awardlead">
                            <h2 className="cc-righttext">{t("Scientific")}</h2>
                        </div>
                        <div id="w-node-3a996ff11d6b-3a368f7a" className="w-layout-grid grid-33">
                            <div id="w-node-3301fa2f2467-3a368f7a" >
                                <center><img src={"/assets/images/partners/MINEC.png"} className="partner" alt="" /></center>
                                <div className="d_awardtitle"><h5 className="cc-awardtitle"><a href="http://www.minec.ac/" target="_blank">MINEC</a></h5></div>
                            </div>
                            <div id="w-node-3301fa2f2467-3a368f7a" >
                                <center> <img src={"/assets/images/partners/Digital-Dental-Society-Study-Club.png"} className="partner" alt="" /></center>
                                <div className="d_awardtitle"><h5 className="cc-awardtitle"><a href="https://digital-dentistry.org/" target="_blank">Digital Dental Society</a></h5></div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className="s_awards">
                <div className="c_page">
                    <div className="w-layout-grid grid-26">
                        <div id="w-node-907d6837e851-3a368f7a" className="d_awardlead">
                            <h2 className="cc-righttext">{t("formations")}</h2>
                        </div>
                        <div id="w-node-3a996ff11d6b-3a368f7a" className="w-layout-grid grid-33">
                            <div id="w-node-3301fa2f2467-3a368f7a" >
                                <center> <img src={"/assets/images/partners/WIIC-logo.png"} className="partner" alt="" /></center>
                                <h5 className="cc-awardtitle"><a href="https://wehrleimplantimmersion.com/" target="_blank">WIIC</a></h5>
                            </div>
                            <div id="w-node-3301fa2f2467-3a368f7a" >
                                <center> <img src={"/assets/images/partners/AIE.png"} className="partner" alt="" /></center>
                                <div className="d_awardtitle"><h5 className="cc-awardtitle"><a href="https://www.advimplanteducators.com/" target="_blank">AIE</a></h5></div>
                            </div>
                            <div id="w-node-3301fa2f2467-3a368f7a" >
                                <center> <img src={"/assets/images/partners/Implant-Know-How.jpg"} className="partner" alt="" /></center>
                                <div className="d_awardtitle"><h5 className="cc-awardtitle"><a href="https://implantknowhow.com/" target="_blank">Implant Know How</a></h5></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PartnersGMTC
