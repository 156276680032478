import React, { useEffect, useState, useRef } from 'react';

const Slider = ({ images, interval, startDelay }) => {
  const [currentIndex, setCurrentIndex] = useState(1); // Start from the first actual image
  const [startSliding, setStartSliding] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setStartSliding(true);
      const slideInterval = setInterval(() => {
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, interval);

      return () => clearInterval(slideInterval);
    }, startDelay);

    return () => clearTimeout(delayTimeout);
  }, [interval, startDelay]);

  useEffect(() => {
    const transitionEndHandler = () => {
      if (currentIndex === images.length + 1) {
        containerRef.current.style.transition = 'none';
        setCurrentIndex(1);
      } else if (currentIndex === 0) {
        containerRef.current.style.transition = 'none';
        setCurrentIndex(images.length);
      }
    };
  
    // Store the current value of containerRef in a local variable
    const currentContainerRef = containerRef.current;
  
    // Use the local variable to add the event listener
    currentContainerRef.addEventListener('transitionend', transitionEndHandler);
  
    return () => {
      // Check if the local variable is not null before removing the event listener
      if (currentContainerRef) {
        currentContainerRef.removeEventListener('transitionend', transitionEndHandler);
      }
    };
  }, [currentIndex, images.length]);
  

  useEffect(() => {
    if (currentIndex === images.length + 1 || currentIndex === 0) {
      return;
    }

    containerRef.current.style.transition = 'transform 1s ease-in-out';
    containerRef.current.style.transform = `translateX(${-currentIndex * 100}%)`;
  }, [currentIndex]);

  return (
    <div className="sliderX">
      <div
        ref={containerRef}
        className={`slider-containerX ${startSliding ? 'slideX' : ''}`}
        style={{ transform: `translateX(${-currentIndex * 100}%)` }}
      >
        <img
          src={images[images.length - 1].url ? images[images.length - 1].url : `https://gdtc.ac/public/uploads/sliders/${images[images.length - 1].path}`}
          alt={images[images.length - 1].alt ? images[images.length - 1].alt : 'image '+images[images.length - 1]}
          className="slider-imageX"
        />
        {images.map((image, index) => (
          <img
            key={index}
            src={image.url ? image.url : `https://gdtc.ac/public/uploads/sliders/${image.path}` }
            alt={image.alt? image.alt : 'image '+index }
            className="slider-imageX"
          />
        ))}
        <img
          src={images[0].url ? images[0].url : `https://gdtc.ac/public/uploads/sliders/${images[0].path}` }
          alt={images[0].alt ? images[0].alt : 'image 0'}
          className="slider-imageX"
        />
      </div>
    </div>
  );
};

export default Slider;
