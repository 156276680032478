import React from 'react'
import { useTranslation } from "react-i18next";
const About = () => {
    const { t } = useTranslation();
    return (
        <>
              <section className="presentation_page" >
   <strong>Introduction</strong>
   <p>{t("about_1")}</p>
    <strong>{t("about_2")}</strong>
    <p>{t("about_3")}</p>
   <strong>{t("about_4")}</strong>
 <p>{t("about_5")}</p>
  <strong>{t("about_6")}</strong>
  <p>{t("about_7")}</p>
  <strong>{t("about_8")}</strong>
  <ul>
    <li><strong>{t("about_9")}</strong>{t("about_10")}</li>
    <li><strong>{t("about_11")}</strong>{t("about_12")}</li>
    <li><strong>{t("about_13")}</strong>{t("about_14")}</li>
  </ul>
  <strong>{t("about_15")}</strong>
<p>{t("about_16")}</p>
    <strong>Conclusion</strong>
    <p>{t("about_17")}</p>

    
    </section>
        </>
    )
}

export default About
