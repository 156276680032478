import React from "react";


export const Soon = ()=> {
return (
<div>
    <img src={"assets/images/Coming-soon-GDTC.png"}/>
</div>
);
};

export default Soon;