import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { API_BASE_URL } from "../../../config";
import axios from "axios";
import { CAlert } from '@coreui/react';
const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();
    const validEmailRegex = RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (validEmailRegex.test(email)) {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("email", email);
            formData.append("subject", subject);
            formData.append("message", message);

            axios
                .post(API_BASE_URL + "/contact", formData)
                .then((res) => {
                   
                     if (res.statusText.toString() === "OK") {
                    setSuccess(true);
                    setName("");
                    setEmail("");
                    setSubject("");
                    setMessage("");
                    setSubmitted(false);
                    }
                })
                .catch((error) => {
                    console.log(" errors : ", error);
                });
        }


    }
    return (
        <>
            <div className="w-layout-grid grid-32">
                <div className="w_contact_hero_left">

                </div>
                <div id="w-node-707b17e1ba72-9bc30d38" className="w_contact_hero_right"><h2>{t("contact_us")}</h2>
                    <div className="w_contact_page_address">{t("adresse")} : {t("tunisie")}
                        <br />{t("phone")}:  <a href="tel:+216 98 464 490">  216 98 464 490</a>
                        <br /><a href="mailto:contact@gdtc.ac">contact@gdtc.ac</a></div>
                    <p>{t("contact_p")}</p>
                    <div className="html-embed-2 w-embed w-script">


                        <div className="fsBody fsEmbed">


                            <form onSubmit={handleSubmit} className="form-validate">

                                <div className="form-group">
                                    <label className="fsLabel">{t("contact_form_name")}</label>
                                    <input type="text" className="form-control" name="name" value={name} onChange={(e) => setName(e.target.value)}></input>
                                    {submitted && !name && (
                                        <span className="invalid-feedback error">
                                            Required
                                        </span>
                                    )}

                                </div>
                                <div className="form-group">
                                    <label className="fsLabel">{t("contact_form_email")}</label>
                                    <input type="text" className="form-control" name="email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
                                    {submitted && !validEmailRegex.test(email) && (
                                        <span className="invalid-feedback error">
                                            Invalid email
                                        </span>
                                    )}

                                </div>

                                <div className="form-group">
                                    <label className="fsLabel">{t("contact_form_subject")}</label>
                                    <input type="text" className="form-control" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)}></input>
                                    {submitted && !subject && (
                                        <span className="invalid-feedback error">
                                            Required
                                        </span>
                                    )}

                                </div>
                                <div className="form-group">
                                    <label className="fsLabel">{t("contact_form_message")}</label>
                                    <textarea name="message" value={message} className="form-control textarea-form" rows="10" onChange={(e) => setMessage(e.target.value)}></textarea>
                                    {submitted && !message && (
                                        <span className="invalid-feedback error">
                                            Required
                                        </span>
                                    )}
                                </div>

                                <div className="form-group">
                                    <button id="fsSubmitButton3395640" className="btn_black w-button btn-block"  type="submit"  >{t("button_submit")}</button>
                                </div>
                                {success && (
                                    <CAlert color="success">
                                        {`Votre message a été bien envoyé`}
                                    </CAlert>
                                )}



                            </form>

                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}

export default Contact
