

import { API_BASE_URL } from "../config";
import { useHistory } from 'react-router';
import {
    AUTH_USER,
    AUTH_ERROR,
    ADD_TO_BASKET,
    REMOVE_FROM_BASKET,
    FETCH_BASKET
} from "./type";
import axios from "axios";
import apiClient from "src/components/apiClient";

export function registerUser({ formData }) {

    return dispatch => {
        axios
            .post(`${API_BASE_URL}/register`, { formData })
            .then((response) => {
                dispatch({ type: AUTH_USER });
                // localStorage.setItem("token", response.data.token);
                // localStorage.setItem("username", response.data.user.name);
                // useHistory.push('/posts');
                console.log(response)
            })
            .catch((response) => {
                dispatch(authError(response));
                console.log(response.error)
                /*   if (
                       response.data.errors.email.toString() ===
                       "The email has already been taken."
                   ) {
                       authError("The email has already been taken.");
                   }*/
            });
    };
}

const getAuthToken = () => {
    //return localStorage.getItem("token");
    return localStorage.getItem("auth");
};

export function authError(error) {
    return {
        type: AUTH_ERROR,
        payload: error,
    };
}
export function addToBasket({ training }) {
    console.log(" in actions");
    return dispatch => {
        const basket = JSON.parse(localStorage.getItem('basket')) || [];



        if (basket.indexOf(basket.find(o => o.id === training.id)) == -1) {
            basket.push(training);


        }
        localStorage.setItem('basket', JSON.stringify(basket))


        dispatch({
            type: ADD_TO_BASKET,
            payload: training
        })

    }



};

export function addToBasketTraining(id) {
    console.log('in adding' , id , '-----')

    return dispatch => {
        const formData = new FormData();
        //formData.append("userId", localStorage.getItem('id'));
        formData.append("trainingId", id);

        return apiClient.post("/member-api/cart/addtocartTraining", formData)
            .then((response) => {
                console.log('add ress' , response)
                if (response.status === 200) {
                    dispatch({
                        type: ADD_TO_BASKET,
                        payload: response.data
                    });
                    return true;
                } else {
                    return false;
                }
            })
            .catch((respone) => {
                console.log('add ress err' , response)
                return false;
            });
    };
};

export function addToBasketSession(id) {
    console.log(" in actions sssss");
    return dispatch => {

        {
            //   setSubmitted(true);
            const formData = new FormData();;
            //formData.append("userId", localStorage.getItem('id'));
            formData.append("sessionId", id);
           return apiClient.post("/member-api/cart/addtocartSession", formData)
                .then((response) => {
                    console.log('addd sssss res', response);
                    if (response.status == 200) {
                        dispatch({
                            type: ADD_TO_BASKET,
                            payload: response.data
                        })
                        return true

                    } else {
                        return false
                    }
                })
                .catch((response) => {
                    console.log(response);
                    return false
                });

        }

    }



};

export function removeFromBasketOld({ training }) {
    return dispatch => {
        const basket = JSON.parse(localStorage.getItem('basket')) || [];
        basket.splice(basket.indexOf(basket.find(o => o.id === training.id)), 1);
        localStorage.setItem('basket', JSON.stringify(basket))
        dispatch({
            type: REMOVE_FROM_BASKET,
            payload: training
        })

    }
}

export function removeFromBasket(item , type) {
    return dispatch => {

        {
            //   setSubmitted(true);
            const formData = new FormData();;
            //formData.append("userId", localStorage.getItem('id'));
            formData.append("item", item.id);
            formData.append("type", type);
           return apiClient.post("/member-api/cart/removeItem", formData)
                .then((response) => {
                    console.log('remove sssss res', response);
                    if (response.status == 200) {
                        dispatch({
                            type: REMOVE_FROM_BASKET,
                            payload: response.data
                        })
                        return true

                    } else {
                        return false
                    }
                })
                .catch((response) => {
                    console.log(response);
                    return false
                });

        }

    }
}

export function emptyBasket() {
    return dispatch => {

        {
            //   setSubmitted(true);
           // const formData = new FormData();;
           // formData.append("userId", localStorage.getItem('id'));

           return apiClient.post("/member-api/cart/emptyCart")
                .then((response) => {
                    console.log('remove sssss res', response);
                    if (response.status == 200) {
                        dispatch({
                            type: REMOVE_FROM_BASKET,
                            payload: response.data
                        })
                        return true

                    } else {
                        return false
                    }
                })
                .catch((response) => {
                    console.log(response);
                    return false
                });

        }

    }
}

export function fetchBasketOld() {

    let basketItems = [];
    basketItems = JSON.parse(localStorage.getItem('basket'))

    return {
        type: FETCH_BASKET,
        payload: {
            basketItems
        }
    }
}

// Add fetchBasket function
export function fetchBasket() {
    return dispatch => {
        if(localStorage.getItem("auth") && localStorage.getItem("roles") && localStorage.getItem("roles")=='ROLE_PARTICIPANT')
              apiClient.get("/member-api/cart/getCart")
            .then((response) => {
                console.log('get cart', response)
             if(response.data)   dispatch({
                    type: FETCH_BASKET,
                    payload: response.data
                });
            else 
            console.log('nooooo cart')

            })
            .catch((error) => {
                console.error("Error fetching basket:", error);
            });
    };
}

