import React, { useEffect, useState , useRef } from "react";
import { API_BASE_URL, IMAGES_BASE_URL } from "../../../config";
import apiClient from "src/components/apiClient";
import axios from 'axios'
import { } from "@coreui/react";
import { connect } from 'react-redux';
import * as actions from '../../../reducers/actions';
import {CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
  CCarousel,
  CCarouselIndicators,
  CCarouselInner,
  CCarouselItem,
  CCarouselCaption,
  CCarouselControl,
  CImage,
  CSpinner

} from "@coreui/react";
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { MEDIA_URL , TEACH_URL} from "../../../config";

const takeCourse = (props) => {
  const { id } = props.match.params;
  const { type } = props.match.params;
    const [formation, setFormation] = useState([]);
    const [modules, setModules] = useState([]);
    const [error, setError] = useState(null);
    //const [errorTest, setErrorTest] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
   // const [submitting, setSubmitting] = useState(false);
    const [module, setModule] = useState(null);
    const [onSiteSession, setOnSiteSession] = useState(null);
    const [moduleIndex, setModuleIndex] = useState(0);
    const [visible, setVisible] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorCart, setErrorCart] = useState(false);
   // const [userAnswers, setUserAnswers] = useState({});
    //const [userTests, setUserTests] = useState(null);
    //const [userTestsIds, setUserTestsIds] = useState(null);

    const [videoUrl, setVideoUrl] = useState(null);

    const [videoCache, setVideoCache] = useState({});

     useEffect(() => {
    console.log('in use ef')
  
    //if(module)  fetchVideo();
    }, [module]);

    useEffect(() => {
      const fetchVideo = async () => {
        try {
          // Check if the video is already in the cache
          if (videoCache[module.id]) {
            console.log('Video fetched from cache:', videoCache[module.id]);
            setVideoUrl(videoCache[module.id]); // Use the cached video URL
          } else {
            console.log('Fetching video from API...');
            const response = await axios.get(`${API_BASE_URL}/member-api/formation/video/${module.id}`, {
              withCredentials: true,
              responseType: 'blob',
            });
    
            // Convert the video Blob to a URL
            const videoBlobUrl = URL.createObjectURL(response.data);
    
            // Cache the new video URL
            setVideoCache((prevCache) => ({
              ...prevCache,
              [module.id]: videoBlobUrl,
            }));
    
            // Set the video URL
            setVideoUrl(videoBlobUrl);
            console.log('Video fetched from server:', videoBlobUrl);
          }
        } catch (error) {
          console.error('Error fetching video:', error);
        }
      };
    
      if (module) {
        fetchVideo();
      }
    
      // Clean up function to revoke object URLs when the component is unmounted
      return () => {
        if (videoUrl) {
          URL.revokeObjectURL(videoUrl); // Revoke the old video URL to avoid memory leaks
        }
      };
    }, [module, videoCache]); // Added videoCache as a dependency to ensure state consistency
    
  

    useEffect(() => {
      let typ = "all"
if(type) {console.log('yestyyppp',type) , typ = 'session' }
  else console.log('notyyppp',type)
        apiClient(`/member-api/training/${id}/${typ}`)
          .then((res) => res.data)
          .then(
            (result) => {
              console.log('resssss',result)
              setFormation(result);
              setIsLoaded(true);
              setModules(listModules(result));
              setModule(listModules(result)[0]);

             /* axios(`${API_BASE_URL}/memberTests/${localStorage.getItem('id')}`)
              .then((res) => res.data)
              .then(
                (result) => {
                  console.log('mm tsssssss',result)
                  setUserTests(result);
                  let total = [];
  
                    for (let j = 0; j < result.length; j++) {
                      if (result[j].module && result[j].module.id ) {
                        total.push(result[j].module.id );
                      }
                  console.log('tottt' , total);
                   setUserTestsIds(total)
                    }
                
                },
                (error) => {
                  console.log(error);
                 // setError(error)
                }
              );*/

            },
            (error) => {
              console.log(error);
              setError(error)
            }
          );

        
    // const mid=11;
    //       axios(`${API_BASE_URL}/formation/video/${mid}`)
    //       .then((res) => res.data)
    //       .then(
    //         (result) => {
    //           console.log("video: ");
    //           console.log(result);
    //         },
    //         (error) => {
    //           console.log(error);
    //         }
    //       );

      }, [id]);

      const listModules = (formation) => {
        let total = [];
        for (let i = 0; i < formation.sessions.length; i++) {
          for (let j = 0; j < formation.sessions[i].modules.length; j++) {
            //if (formation.sessions[i].modules[j].link || formation.sessions[i].modules[j].type=='test')
               {
              total.push(formation.sessions[i].modules[j]);
            }
          }
        }
        return total;
      };

      const sessionTotalDuration = (session) => {
        let total = 0;
        for(var j = 0; j < session.modules.length; j++){
          if(session.modules[j].duration)  total=total+session.modules[j].duration;
        }
        return total ;
      }

      const handleChangeModule = (event , ordered) => {
        console.log('ordered', ordered ,modules)

        if((type && type == 'session') && !ordered) {setVisible(true) ; 
        console.log('ordered yyyyesss')    
          return;}
        let i = modules.findIndex(
          (item) => item.id == event.target.value
        )
        setModuleIndex(i)
if(modules[i].link || modules[i].type == 'test')

        setModule(modules[i]);
        setVideoUrl(null)
        setOnSiteSession(null)
        console.log(modules[i]);
      }

      const handleSessionOnSiteClick = (el) => {
     if(el.OnSite)   {console.log('sees clicked' , el.id)
      setOnSiteSession(el)
    //setModule(null)
    }
      }

      const purchaseNow = () => {
      
          setSubmitting(true);
          console.log('formation.id '  , formation.id)
    
          props.addToBasketTraining(formation.id).then(success => {
           console.log('success ? '  , success)
            if (!success) {
              setErrorCart(true);
              setSubmitting(false);
              
            } else {
              setErrorCart(false);
              setSuccess(true);
              setSubmitting(false);
              props.history.push("/basket");
            }
          }).catch(() => {
            setSubmitting(false);
            setErrorCart(true);
           
          });
        
      };

      const handleNext = () => {
        if(moduleIndex<(modules.length-1)){
          let i = moduleIndex + 1;
        setModuleIndex(i);
        setModule(modules[i])
        setVideoUrl(null)
        setOnSiteSession(null)

      }}

      const handlePrevious = () => {
        if(moduleIndex>0){
          let i = moduleIndex - 1;
        setModuleIndex(i);
        setModule(modules[i])
        setVideoUrl(null)
        //setOnSiteSession(null)

      }}

      if (error) {
        return (
          <div className="container">
            <main className="l-main">
              <div className="l-section l-section--archive">
                <div>Erreur : {error.message}</div>
              </div>
            </main>
          </div>
        );
      } else if (!isLoaded) {
        return (
          <div className="container">
            <main className="l-main">
              <div className="l-section l-section--archive">
              <div className='loadingMain'>
      <CSpinner color="default" size="sm" />
    </div>
              </div>
            </main>
          </div>
        );
      } else {
        return(
              <div className="takeCourseWrapper" >

<CModal alignment="center" visible={visible} onClose={() => setVisible(false)} >
          <CModalHeader>
            <CModalTitle>
              Alerte
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <>
              <br />
     purchase the whole course to continue 
              <br />
            </>
          </CModalBody>
          <CModalFooter>

        <CButton color="secondary" onClick={() => setVisible(false)}>
          Close
        </CButton>
        <CButton color="primary" disabled={submitting} onClick={() => purchaseNow()}> 
        {submitting && <CSpinner color="default" size="sm" />} add to cart </CButton>

          </CModalFooter>
        </CModal>
      
<div className="contentSideBar">
<div className="courseTitle">{formation.title}</div>
{module && formation.sessions.length>0 &&
formation.sessions.map((el,i)=>{
return (
<div className={`sessionSection ${(el.ordered ? 'active' : 'disabled')}`}>
<div className="sessionSectionHeading" style={el.OnSite?{cursor:'pointer'}:{}} onClick={()=>handleSessionOnSiteClick(el)}
>
{el.title} ({sessionTotalDuration(el)}m)
</div>
<ul>
{el.modules.map((m,i)=>{
{/* if(m.link || m.type == 'test')  */}
return(
    <li className={`moduleList ${(el.ordered ? 'active' : 'disabled')}`}>
    <div class="form-check">
  <input 
  checked = {m.id == module.id? true:false} 
  class="form-check-input" type="radio" name="flexRadioDefault" id={m.id} value={m.id} onChange={(e)=>handleChangeModule(e,el.ordered)}/>
  <label class="form-check-label" for={m.id}>
   {m.type=='video'?
   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-play-btn" viewBox="0 0 16 16"> <path d="M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z"/> <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/> </svg>
   : m.type=='test'? 
   <img src='/assets/icons/test-icon.svg' />
   :
   <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16"> <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/> <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/> </svg>
   }
   {++i}- {m.title} ({m.type=='video'? m.duration+'m': m.type=='test' ? 'test':'file'})
  </label>
</div>
    </li>
  )
})
}
</ul>

</div>
)
  
})
}

</div>
<div className="contentCurrentModule">
<div className="contentVideo">
<div className="contentVideoHeading">
<h3>{onSiteSession ?onSiteSession.title : module?module.title:' '} </h3>
{!onSiteSession && module && module.description && module.description.length>0 
&& 
<> <br /> <h5
dangerouslySetInnerHTML={{ __html: module.description }}>
</h5></> 
}
<div className="videoHeadingButtons">
<span onClick={handlePrevious}>Previous Module</span>&nbsp;&nbsp;
<span onClick={handleNext}>Complete and Continue</span>

</div>
</div> <br /> <br />

{ onSiteSession ? 


                        <div className="row col-md-12 " style={{ margin: "auto" }}>
                      
                          {onSiteSession.description && onSiteSession.description != 'null' &&
                            <h5>
                              <p dangerouslySetInnerHTML={{ __html: onSiteSession.description }}></p>
                            </h5>}

                          {onSiteSession.imageSessions && onSiteSession.imageSessions.length > 0 &&
                            <div className="col-md-6" style={{ paddingLeft: "0px", paddingRight: "20px" }}>
                              <CCarousel controls indicators style={{ padding: '0px' }} className="courseSlides">
                                {onSiteSession.imageSessions.map((image, index) => {
                                  return (
                                    image.path &&
                                    <CCarouselItem key={index}>
                                      <CImage className="d-block w-100"
                                        key={index}
                                        src={MEDIA_URL + image.path}
                                      />
                                    </CCarouselItem>
                                  )
                                })}
                              </CCarousel>
                            </div>}
                          {onSiteSession.demo && onSiteSession.demo.length > 0 &&
                            <div className="col-md-6" style={{ paddingRight: "0px", paddingLeft: "20px" }}>
                              <video autoPlay="autoPlay" width="100%" controls muted style={{ borderRadius: '5px' }} key={onSiteSession.demo + onSiteSession.id}>
                                <source src={MEDIA_URL + onSiteSession.demo} type="video/mp4" key={onSiteSession.demo + onSiteSession.id} />
                              </video>
                            </div>}
                        </div>

 : module &&
<>
{
   module.type=='video' && module.link ? 

<>
{videoUrl? <video
  autoPlay
  muted
  playsInline
  width="95%"
  controls
  key={module.id}
  onLoadedData={() => console.log("Video loaded successfully")}
  onError={(e) => console.log("Error loading video", e)}
>     
                  <source
          src={videoUrl}
        type="video/mp4"
      />
          </video>
          :     <div className='loadingMain'>
      <CSpinner color="default" size="sm" />
    </div>}
{/* <ReactHlsPlayer
    src={API_BASE_URL+'/formation/video/11'} 
    autoPlay={false}
    controls={true}
    width="95%"
  /> */}
</>
: module.type=='test' && module.questions? 
<>
<div className="contentVideoHeading">
          <br/><br/>
          <div className="testResults">
<p >Now you have to do the following test before proceeding</p>
{module.duration && module.duration>0 ?
 <p >You have <strong>{module.duration} minutes</strong>  to finish the test , when you click the button below a new page with the test will open and <strong>time will start counting automatically</strong> </p>
 :''}
          </div>
          <Link
          //target="_blank"
          to={{ pathname:`/QCM-test/${module.id}`,
                state:module,
                }}
           //to={`/QCM-test/${module.id}`} 
          className="submitForm" > 
          Click to start the Test</Link>

          {/* <form className="moduleTestForm">
      {module.questions.map((question) => (
        <div key={question.id} className="moduleTestFormDiv" >
          <p>{question.text}</p>
          <div  className="moduleTestFormDivAns">
          {question.answers.map((answer, index) => (
            <div key={index} className="moduleTestFormDivAns1">
              <label>
                <input
                  type="radio"
                  name={`question-${question.id}`}
                  value={index}
                  checked={userAnswers[question.id] === index}
                  onChange={() => handleAnswerChange(question.id, index)}
                />
              <span>{getLetter(index)}.</span>  {answer.text}
              </label>
            </div>
          ))}
          </div>
        </div>
      ))}
      {errorTest && <p style={{ color: 'red' , textAlign:'center' }}>{errorTest}</p>}

      {userTestsIds && userTestsIds.includes(module.id) ? 
       <>
       <div className={`testResults ${getResultByModuleId(module.id)>49? 'testPassed' : 'testFailed'}`}>
          <p>You have already did this test with a : {getResultByModuleId(module.id)} % result</p>
        </div>
       <button className="submitForm" onClick={handleSubmit} disabled >
         Submit</button>
         </> 
      : 
      <button className="submitForm" onClick={handleSubmit} disabled={submitting} >
      {submitting &&   <CSpinner color="default" size="sm" />} Submit</button>
      }

      {result && (
        <div className={`testResults ${result.percentage>49? 'testPassed' : 'testFailed'}`}>
          <p>Total Correct Answers: {result.correctAnswers}</p>
          <p>Percentage: {result.percentage}%</p>
        </div>
      )}

    </form> */}

          </div>
</>
          :
          <div className="contentVideoHeading">
          <br/><br/>
          <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-file-text" viewBox="0 0 16 16"> <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/> <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/> </svg>
          <a href={TEACH_URL+module.link} target="_blank">
          {module.link.split('/')[module.link.split('/').length-1]}
          </a>
          </div>
          }</>
}
</div>
<br/>
</div> 
        </div>
        )}    
}
const mapStateToProps = (state) => {
  console.log(state)
  return {
    basketItems: state.basket.basketItems
  };
}
export default connect(mapStateToProps, actions)(takeCourse)
//export default takeCourse
