import React, { useState } from 'react'
import { API_BASE_URL } from '../../../config'
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CInputGroup,
  CRow,
  CSpinner,CAlert,
} from '@coreui/react'
import { useTranslation } from "react-i18next";


const ForgetPass = (props) =>  {

  const { t } = useTranslation();

    const [submitted, setSubmitted] = useState(false);
    const [isloading, setisloading] = useState(false);
    const [email, setEmail] = useState("");
    const [loginErrors, setloginErrors] = useState("");
    const [errors, seterrors] = useState("");
    const [token, settoken] = useState(0);
    const [vcode, setvcode] = useState("");
    const [vcode2, setvcode2] = useState("");
    const [password, setpassword] = useState("");
    const [passwordConfirmation, setpasswordConfirmation] = useState("");
    const [success, setsuccess] = useState("");
    const [returnedtime, setreturnedtime] = useState(0);

    // handleChange=(event)=>{
    //     this.setState({
    //         [event.target.name] :event.target.value
    //     })
    //     console.log(this.state);
    // }

    const validEmailRegex = RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

 const handleSubmit=(event)=>{
console.log("submit");
event.preventDefault();
      //this.setState({ errors:""});
      seterrors("");

      if(!validEmailRegex.test(email) ){
        //this.setState({ errors:"Veuillez entrer votre adresse e-mail associée !"})
       // seterrors("Veuillez entrer votre adresse e-mail associée !");
        seterrors(t("enter_your_associated_email_address") +" !");
      } 
        else {
        // this.setState({
        //   isloading: '1'
        // });
       setSubmitted(true);
        setisloading(true);
        const formData = new FormData();

        formData.append("email", email);
        
        console.log('requesting forget');
      axios.post(API_BASE_URL+'/members/forget',formData)
      .then(res => {
        console.log(res);
        if(res.data.status=='ok'){
         
          //this.setState({ isloading: '0',vcode:res.data.code,token:'1'});
          //setreturnedtime(res.data.times);
          setisloading(false);
          //setvcode(res.data.code);
          settoken(1);
          setSubmitted(false);
        }else if (res.data.status=='fail'){
          setisloading(false);
          //seterrors("Cet e-mail n'est pas associé à un compte existant !");
          seterrors(t("This_email_not_associated_with_account") +" !");
          setSubmitted(false);
        }
        
      })
      .catch(error =>{
        console.log("reset errors : ", error);
        setSubmitted(false);
    })
   
  }
     
  }
   const handleSubmit2=(event)=>{
    console.log("submit2");
    event.preventDefault();  
    seterrors("");
    setSubmitted(true);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("code",vcode2);
    axios.post(API_BASE_URL+'/members/checkToken',formData)
      .then(res => {
        console.log('check token: ',res);
        if(res.data.status=='ok'){
         
          //this.setState({ isloading: '0',vcode:res.data.code,token:'1'});
       settoken(2);
        setSubmitted(false);
        } else if (res.data.status=='expire') {
          //this.setState({ isloading: '0',errors:"Cet e-mail n'est pas associé à un compte existant !"});
         seterrors(t("code_expired"));
         setSubmitted(false);
        } else if (res.data.status=='wrong') {
          //this.setState({ isloading: '0',errors:"Cet e-mail n'est pas associé à un compte existant !"});
         seterrors(t("wrong_code"));
         setSubmitted(false);
        } else {
           seterrors("error !");
           setSubmitted(false);
        }
        
      })
      .catch(error =>{
        console.log("reset errors : ", error);
        setSubmitted(false);
    })

 
}
 const handleSubmit3=(event)=>{
  event.preventDefault();
  console.log("submit 3");
  if(password.length<6){
    //this.setState({ errors:"Le mot de passe doit contenir au moins 8 caractères!"});
    seterrors(t("password_6chars"));
  } else if (passwordConfirmation !== password) {
    //this.setState({ errors:"La confirmation ne correspond pas au mot de passe!"}); 
    seterrors(t("password_not_confirmed")); 
  } else {
    // this.setState({isloading: '1'});
    // this.setState({ errors:""}); 
    setSubmitted(true); 
    setisloading(true);
    seterrors("");
    const formData = new FormData();
    formData.append("password", password);
    formData.append("passwordConfirmation", passwordConfirmation);
    formData.append("email", email);

    console.log('formdata');
    console.log(formData);
    axios
    .post(API_BASE_URL + "/members/updatepass", formData)
    .then((res) => {
      console.log(res.data);
      if(res.data.status=='ok'){
        // setTimeout(() => {
        //   this.setState({isloading: '0',success:'Votre mot de passe a été réinitialisé avec succès. Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.'})
        // }, 200);
        setSubmitted(false);
        setisloading(false);
        setsuccess(t("password_reset_successfully"))
        setTimeout(() => {
          //window.location.href = "/login";
          props.history.push("/login");
        }, 3000);
      }else {  
      //  setTimeout(() => {
      //   this.setState({isloading: '0',errors:'Une erreur est survenue, veuillez réessayer ultérieurement'})
      // }, 200);
      setSubmitted(false);
      setisloading(false);
      seterrors(t("error_try_again"));    
    }
    })
        
    .catch((error) => {
      console.log("Register errors : ", error);
      // setTimeout(() => {
      //   this.setState({isloading: '0',errors:'Une erreur est survenue, veuillez réessayer ultérieurement'})
      // }, 200);
      setSubmitted(false);
      setisloading(false);
      seterrors(t("error_try_again"));
    
    });
  
  }

   
}
const LoginRedirect=()=>{
  console.log('loggggggggg');
  window.location.href = '/login';
}
  
   
        return (
          <div className="c_full_vieport_container cc_individual_case">
          <div className="em_custom_styles w-embed">
              <div className="s_indv_port_text_sec" >
                  <div className="w_commercial_alternating_cards cc_text_group_7" >
                      <div className="gi_rep w-dyn-item " style={{ width: "100%" }} >
    {token==0 &&   
    <form onSubmit={handleSubmit} className="form-validate">

<h3>{t("forgot_your_password")} ?</h3>


<div className="fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100" id="login">
    <label className="fsLabel fsRequiredLabel"
        htmlFor="name">{t("enter_your_associated_email_address")}:<span
            className="fsRequiredMarker">*</span> </label>


    <input type="email" value={email} size="55"
        name="name" className="fsField form-control" onChange={(e) => setEmail(e.target.value)}
    />

</div>

<br />
{ errors.length>0 && (
<CAlert color="danger" >
{errors}
</CAlert>
)}

{!submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
    <input id="fsSubmitButton3395640" className="btn_black w-button btn-block"
        type="submit" value={t("send_me_verification_code")} width="80%" />

</div>}
{submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
    <input disabled id="fsSubmitButton3395640" className="btn_black w-button btn-block"
        type="submit" value={t("send_me_verification_code")} width="80%" /> <CSpinner style={{display:"block",margin:"auto",marginTop:"10px"}} color="default" size="sm" />

</div>}
<br />

</form>}

{token==1 &&
  <form onSubmit={handleSubmit2} className="form-validate">

<h3>{t("forgot_your_password")} ?</h3>


<div className="fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100" id="login">
    <label className="fsLabel fsRequiredLabel"
        htmlFor="name"> {t("forgot_code_sent_enter_it")} :<span
            className="fsRequiredMarker">*</span> </label>
   <input type="text" placeholder={t("verification_code")} className="fsField form-control" size="10" name="vcode2" value={vcode2} onChange={(e) => setvcode2(e.target.value)}/>

</div>

<br />
{ errors.length>0 && (
<CAlert color="danger" >
{errors}
</CAlert>
)}

{!submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
    <input id="fsSubmitButton3395640" className="btn_black w-button btn-block"
        type="submit" value={t("submit")} width="80%" />

</div>}
{submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
    <input disabled id="fsSubmitButton3395640" className="btn_black w-button btn-block"
        type="submit" value={t("submit")}  width="80%" /> <CSpinner style={{display:"block",margin:"auto",marginTop:"10px"}} color="default" size="sm" />

</div>}
<br />

</form>
}

{token==2 &&
  <form onSubmit={handleSubmit3} className="form-validate">

<h3>{t("forgot_your_password")} ?</h3>
<h4>{t("enter_new_password")} :</h4>

<div className="fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100" id="login">
    <label className="fsLabel fsRequiredLabel"
        htmlFor="name">{t("password")}<span
            className="fsRequiredMarker">*</span> </label>
   <input className="fsField form-control" size="55"
                          type="password"
                          name="password"
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                        />
</div>

<div className="fsRowBody fsCell fsFieldCell fsFirst fsLast fsLabelVertical fsSpan100" id="login">
    <label className="fsLabel fsRequiredLabel"
        htmlFor="name">{t("confirm_password")}<span
            className="fsRequiredMarker">*</span> </label>
   <input className="fsField form-control" size="55"
                          type="password"
                          name="passwordConfirmation"
                          value={passwordConfirmation}
                          onChange={(e) => setpasswordConfirmation(e.target.value)}
                        />
</div>

<br />
{ errors.length>0 && (
<CAlert color="danger" >
{errors}
</CAlert>
)}
{success.length>1 && (
<CAlert color="success" >
{success}
</CAlert>
)}

{!submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
    <input id="fsSubmitButton3395640" className="btn_black w-button btn-block"
        type="submit" value={t("submit")}  width="80%" />

</div>}
{submitted && <div id="fsSubmit3395640" className="fsSubmit fsPagination">
    <input disabled id="fsSubmitButton3395640" className="btn_black w-button btn-block"
        type="submit" value={t("submit")}  width="80%" /> <CSpinner style={{display:"block",margin:"auto",marginTop:"10px"}} color="default" size="sm" />

</div>}
<br />

</form>
}




            </div>
                        </div>
                    </div>
                </div>
            </div>

                  
        )
    
}

export default ForgetPass;
