import React from 'react'
import { useTranslation } from "react-i18next";

const Greeting = () => {
    const { t } = useTranslation();
    return (
        <>
         <div class="w_commercial_alternating_cards cc_text_group_7">
        <center>
            <video autoplay="autoplay"  width="100%" controls muted >
                <source src={"assets/videos/gdtc.mp4"} type="video/mp4"/>

            </video>
        </center>
        <h2>{t("greeting_title")}</h2>


        <h2 class="h2_alternating_card" data-gsap-animate="true">Kwang Bum Park DDS, MS, PhD
            MINEC BOARD OF DIRECTORS
            <br/> CEO MegaGen</h2>
        <img src={"assets/images/team/Dr.Kwang-Bum-Park-gdtc.jpeg"} height="200px"/>
        <p></p>
        <p>
            Hello everyone, this is Dr. Kwang Bum Park sending my very best greetings from Daegu, Korea.
            First, let me say how inspired and proud I am of Dr. Achraf….for taking the initiative to gather his
            friends, colleagues, and mentors to create this wonderful online event that reaches out to the whole
            world.
            I am also very pleased with this special line-up of talented clinicians. As you can see, there are
            no older speakers with big reputations… only young, enthusiastic, gifted, and dedicated dentists who
            are motivated and excited to share their important experiences and key insights. This means we
            senior clinicians can happily relax and support them as moderators!
        </p>
        <p>Actually, this truly captures the heart of my vision when I started MINEC with a small group of young
            dentists, 4 of whom - Achraf, Davide, Mitsias, and Sang Yeup Lee - are included in today’s speaker
            line-up.
            This initial group was given the title of MINEC Knights, as my idea was to create a close-knit group
            who would train and learn together, share their ideas and discoveries, discuss to find the right
            principles, and all become champion international educators. </p>
        <p>I am proud to say that most of the other speakers today have also been selected as MINEC Knights –
            Isaac, Irfan, Cem, Nicolas, Andes, Guido, and Fabrizio.</p>
        <p>Now we have almost 100 MINEC Knights from all over the world, and I have had the real pleasure to
            watch all these young dentists grow over the years…. grow in personal confidence and maturity, in
            expertise and skills, in authority and insight, and most of all in heart and empathy… to make a
            difference and embrace the basic philosophy of MINEC to improve the patient experience, improve the
            quality of implant treatments available, and improve the treatment outcomes available to all.</p>
        <p>As a dentist myself, I started placing implants before most other dentists in Korea. I was really
            excited by this revolution in dental treatment and believed in its positive impact for patients.</p>
        <p>However, the surgical and prosthetic procedures were not easy for unexperienced dentists and
            beginners. So, at MegaGen, we have tried to develop much easier prognostic solutions in terms of
            implant systems and digital dentistry based on important advice from our key opinion leaders and
            input and inspiration from dentists around the world. </p>

        <p>The revolution in implant dentistry will never end, and MegaGen always welcomes your innovative
            ideas.
            Right now, so many dentists are genuinely worried how they will recover after this coronavirus
            crisis is over. Yet, when faced with difficulty, we have an opportunity to find new solutions.
            Therefore, the philosophy behind this webinar is to give hope and encouragement by creating an
            opportunity to become better clinicians so we can all be more successful when this crisis ends. </p>
        <p>This is GROWTH LEADERSHIP….and what Dr. Achraf and his friends and colleagues are showing today. </p>
        <p>So congratulations and bravo to all the speakers and moderators for taking part in this special
            event, which I really hope will be appreciated and enjoyed by many many dental colleagues all over
            the world. </p>
        <p>Let’s make the best dentistry available for all dentists… so the best clinicians can be available for
            all patients. </p>
        <p>Thank you.</p>

        <p></p>
        <h2 class="h2_alternating_card" data-gsap-animate="true">SCOTT GANZ, DMD
            MINEC BOARD OF DIRECTORS
            <br/> Honorary Guest</h2>
        <img src={"assets/images/team/Scott-Ganz-gdtc.jpg"} height="200px"/>
        <p></p>
        <p>As one of the Directors of MINEC, it is a great and special honor to introduce the 1st ONLINE LIVE
            INTERNATIONAL MINEC KNIGHTS SUMMIT!</p>
        <p>Due to widespread pandemic that has affected all reaches of our planet, we must adapt to the new, and
            hopefully temporary normal of staying home to protect our populations. The MegaGen International Network of
            Education & Clinical Research was founded to encourage clinicians to continually strive for excellence in
            their own practice, and for the future of our profession through education and research. With the current
            global crisis, MINEC Knights and Ambassadors decided to gather 12 of it’s finest educators together for the
            first time to present a one-of-a-kind online educational experience! This amazing collaboration of 12
            speakers from 10 different nationalities will provide a unique and special educational opportunity for
            clinicians of all levels. The live stream will air over 7 hours starting on April 30 with the last session
            ending at 18:30. There will be three separate sessions each with four speakers and an expert moderator to
            help with the question and answer segments. The general topics will be Soft Tissue Session, Hard Tissue
            Session, and Digital Workflows Session. Each speaker will have 25 minutes for their presentation, followed
            by questions from the online participants. We are very honored to have Dr. Kwang Bum Park start off the
            Summit with opening remarks and the vision of the MINEC Organization.</p>
        <p>This will be one of the finest educational experiences, representing some of the most talented and gifted
            educators showcasing their amazing efforts. This is a special event necessitated by the times…. and we wish
            to invite our worldwide community to join us and engage in what will be a very memorable, highly
            educational, academic, and clinically enlightening meeting of the minds. Please take this opportunity to
            join with us to expand our knowledge base and bring the world a bit closer together during these times of
            isolation. Hope to see you online!!</p>
        <p>Respectfully –</p>
        <p></p>
        <h2 class="h2_alternating_card" data-gsap-animate="true">Jaafar Mouhyi, DDS, PhD
            Digital Dental Society Board of Directors
            <br/> Honorary Guest</h2>
        <img src={"assets/images/team/Jaafar-mouhyi-gdtc.jpg"} height="200px"/>
        <p></p>
        <p>{t("mouhy_p")}</p>
        <p></p>
        <h2 class="h2_alternating_card" data-gsap-animate="true">André P. Saadoun, DDS, MS
            <br/> Honorary Guest</h2>
        <img src={"assets/images/team/Andre.P.Saadoun-gdtc.jpeg"} height="200px"/>
        <p></p>
        <p>Le Corona Virus ou Covid 19 fait la une des Nouvelles des Radios, des Télévisions et des Journaux dans le
            Monde.</p>
        <p>Cette Epidémie, maintient en Confinement près de 3 Milliards de personnes dans le monde, tout comme était la
            mise à l’écart des malades de la Peste au Moyen Age. On ignorait alors qu’il y avait toujours auparavant une
            Période d’Incubation de 2 semaines, d’où une Population décimée par million aux deux tiers !</p>
        <p>Aujourd’hui si l’on sait confiner on ignore l’Aménagement de cet hypothétique et tardif Déconfinement, faute
            de Médicaments curatifs et de Vaccins, alors nous allons apprendre tous ensemble grâce à Recherche Médicale,
            la Technologie dont l’Intelligence Artificielle.
            Mais en attendant, le Monde a déjà Changé et Changera plus encore et bien des choses ne seront plus
            pareilles à l’Avant Covid 19 : la prise en charge de la Santé, de l’Education, de la Politique, de
            l’Economie et de la Communication seront à réapprendre et à être organisés de manière différente.
            Nous devrons revoir nos méthodes d’enseignement et notre partage de la Science dans tous les domaines !</p>
        <p>Les grands Congrès, les déplacements en avion des conférenciers, les Soirées de Gala de Clôture paraissent
            être déjà de lointain souvenirs dont on ignore quand et s’ ils reviendront un jour ?
            C’est pourquoi le Travail à distance ainsi que les Webinars seront et resteront très certainement, pour les
            Maitres, une nouvelle façon de transmettre leur savoir et pour les Participants, le moyen d’approfondir leur
            connaissance scientifique et Clinique à distance.</p>
        <p>Le Dr Achraf Souayah, mon élève le plus doué jamais rencontré lors de mes formations en Paro -Implantologie,
            est devenu, par son travail constant un Maitre Clinicien et un Conférencier mondial et de renom.</p>
        <p>Avec le Soutien de MegaGen, dans le cadre du MINEC Institute, il propose d’organiser un Exceptionnel Live
            Webinar d’une journée le 30 Avril 2020
            Il a ainsi réuni 12 Conférenciers internationaux de grande valeur avec des Modérateurs tels que le Pr Kwang
            BUM Park, le Pr Scott Ganz, le Pr Jaafar Mouhyi et moi-même pour animer cette journée très spéciale.</p>
        <p>La création d'un sourire esthétique avec une architecture gingivale en harmonie avec une dentition adjacente
            bien alignée / saine reste l'objectif final en Dentisterie.
            Le défi en Implantologie est de gérer le complexe de tissus durs / mous autour des dents / implants et
            d'être le plus proche possible de l'apparence naturelle du sourire du patient dans la zone esthétique avec
            une occlusion fonctionnelle</p>
        <p>Une évolution et une révolution majeures de la dentisterie moderne ont eu lieu très récemment, avec une
            chirurgie moins invasive, des approches plus sophistiquées, utilisant de plus en plus l'intelligence
            artificielle.</p>
        <p>Les thèmes d’actualité de l’Esthétique en Implantologie seront abordés tels que : l’Aménagement des Tissus
            Mous, le Maintien et ou la Reconstruction des Tissus durs et enfin l’importance du Digital dans nos plans de
            traitement implantaire.</p>
        <p>
            Je n’ai aucun doute que cette journée sera et restera un Evènement Mondial en Implantologie et que d’autres
            formations plus détaillées suivront dans les mois suivants
        </p>
    </div>
            
        </>
    )
}

export default Greeting
