import {ADD_TO_BASKET,REMOVE_FROM_BASKET,FETCH_BASKET} from './type';
const INITIAL_STATE = {
    basketItems: {trainings:[] , sessions:[]},
   
  };
export default function basketReducers(state = INITIAL_STATE, action){
    switch (action.type) {
         case ADD_TO_BASKET: 
        console.log(state.basketItems.basketItems)
         return {
            ...state,
           
            basketItems: 
                    action.payload, ...state.basketItems.basketItems
                ,
               
            
            newItem:action.payload
        };
      case REMOVE_FROM_BASKET:
        return {
          ...state,
          basketItems: action.payload
              ,};
      case FETCH_BASKET:
        console.log('action.payloadaction.payload', action.payload)
           return {
            ...state,
            basketItems: action.payload
                ,}

      default:
          return state;
    }

}