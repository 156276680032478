import React from 'react'
import { useTranslation } from "react-i18next";
const Declaration = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="w_commercial_alternating_cards cc_text_group_7" style={{"marginBottom": "10%"}}>
        <h2>{t("declaration_titre")}</h2>
        <p>{t("declaration_p")}</p>

        <h3>{t("quelles_sont_infos")}</h3>
        <p>{t("info_p")}</p>
        <ul>
            <li>{t("info_li1")}</li>
            <li>{t("info_li2")}</li>
            <li>{t("info_li3")}</li>
            <li>{t("info_li4")}</li>
            <li>{t("info_li5")}</li>
        </ul>
        <h3>{t("comment_sont_gerer_titre")}</h3>
        <p>{t("infos_collecte_p")}</p>
        <ul>
            <li>{t("infos_collecte_li1")}</li>
            <li>{t("infos_collecte_li2")}</li>
        </ul>
        <h3>{t("traitement_donnee_titre")}</h3>
        <p>{t("traitement_donnee_p")}</p>
        <h3>{t("cookie_titre")}</h3>
        <p>{t("cookie_p")}</p>
    </div> 
        </>
    )
}

export default Declaration
