import React from 'react'
import { useTranslation } from "react-i18next";
import { Link, NavLink } from 'react-router-dom';
import CourseCard from './courseCard';
import { useState ,useEffect} from 'react';
import { CCol } from "@coreui/react";
import FormateurCard from './formateurCard';
//import { Slide } from 'react-slideshow-image';
//import 'react-slideshow-image/dist/styles.css';
import Slider from './slider';
import { API_BASE_URL } from 'src/config';
import axios from 'axios';
import TrainingCard from './training/TrainingCard';

const HomePage = () => {
    const { t } = useTranslation();
    const [slideImages1, setSlideImages1] = useState([
        {
            url: '/assets/images/slider-1/default-image-1.jpg',
            alt: 'slide 1 image 1'
          },   
                {
            url: '/assets/images/slider-1/default-image-2.jpg',
            alt: 'slide 1 image 2'
          },    
          //     {
          //   url: 'assets/images/slider-1/garcon-palette-pinceau-debout-pres-du-chevalet.jpg',
          //   alt: 'garcon-palette-pinceau-debout-pres-du-chevalet'
          // },       
          //   {
          //   url: 'assets/images/slider-1/homme-atteint-sa-main-pour-pousser-station-alarme-incendie.jpg',
          //   alt: 'homme-atteint-sa-main-pour-pousser-station-alarme-incendie'
          // },
          // {
          //   url: 'assets/images/slider-1/equipe-medias-sociaux-aide-tablette-numerique-icones-medias-sociaux-bureau.jpg',
          //   alt: 'equipe-medias-sociaux-aide-tablette-numerique-icones-medias-sociaux-bureau'
          // },
          // {
          //   url: 'assets/images/slider-1/gros-plan-personne-interrogee-microphone-prenant-declarations.jpg',
          //   alt: 'gros-plan-personne-interrogee-microphone-prenant-declarations'
          // },
          // {
          //   url: 'assets/images/slider-1/homme-camera-filme-mec-haut-parleur-camera.jpg',
          //   alt: 'homme-camera-filme-mec-haut-parleur-camera'
          // },
          // {
          //   url: 'assets/images/slider-1/homme-plein-coup-choisissant-couleurs.jpg',
          //   alt: 'homme-plein-coup-choisissant-couleurs'
          // },
          // {
          //   url: 'assets/images/slider-1/jeune-homme-tenant-commutateurs-ethernet-fils.jpg',
          //   alt: 'jeune-homme-tenant-commutateurs-ethernet-fils'
          // },
          // {
          //   url: 'assets/images/slider-1/jeune-photographe-professionnel-prendre-photos-modele-indien-studio-leight.jpg',
          //   alt: 'jeune-photographe-professionnel-prendre-photos-modele-indien-studio-leight'
          // },
          // {
          //   url: 'assets/images/slider-1/man-working-building.png',
          //   alt: 'man-working-building'
          // },
          // {
          //   url: 'assets/images/slider-1/plein-coup-personnes-portant-pancarte-ensemble.jpg',
          //   alt: 'plein-coup-personnes-portant-pancarte-ensemble'
          // },
          // {
          //   url: 'assets/images/slider-1/travailleur-construction-assemblage-plafond-suspendu-cloison-seche-fixation-cloison-seche-au-cadre-metallique-du-plafond-tournevis.jpg',
          //   alt: 'travailleur-construction-assemblage-plafond-suspendu-cloison-seche-fixation-cloison-seche-au-cadre-metallique-du-plafond-tournevis'
          // },    {
          //   url: 'assets/images/slider-1/videaste-professionnel-homme-indien-gimball-video-slr-ronin-isole-fond-studio.jpg',
          //   alt: 'videaste-professionnel-homme-indien-gimball-video-slr-ronin-isole-fond-studio'
          // },
          // {
          //   url: 'assets/images/slider-1/vue-arriere-du-createur-contenu-travaillant-au-bureau-domicile-pendant-nuit-editeur-video-dans-bureau-domicile.jpg',
          //   alt: 'vue-arriere-du-createur-contenu-travaillant-au-bureau-domicile-pendant-nuit-editeur-video-dans-bureau-domicile'
          // },
    ]);
    const [slideImages2, setSlideImages2] = useState([
        {
          url: '/assets/images/slider-2/default-image-1.jpg',
          alt: 'slide 2 image 1'
        },
        {
          url: '/assets/images/slider-2/default-image-2.jpg',
          alt: 'slide 2 image 2'
        },
        // {
        //     url: 'assets/images/slider-2/travail-artisan-professionnel-atelier.jpg',
        //     alt: 'travail-artisan-professionnel-atelier'
        //   },
        //   {
        //     url: 'assets/images/slider-2/femme-confection-vetements-gros-plan.jpg',
        //     alt: 'femme-confection-vetements-gros-plan'
        //   },
        //     {
        //     url: 'assets/images/slider-2/craftsman-fait-son-nouveau-projet-atelier.jpg',
        //     alt: 'craftsman-fait-son-nouveau-projet-atelier'
        //   },        
        //    {
        //     url: 'assets/images/slider-2/menuisier-professionnel-travaillant-machine-scier.jpg',
        //     alt: 'menuisier-professionnel-travaillant-machine-scier'
        //   }, 
          // {
          //   url: 'assets/images/slider-2/personnes-travaillant-ensemble-pour-nouveau-film.jpg',
          //   alt: 'personnes-travaillant-ensemble-pour-nouveau-film'
          // },
          // {
          //   url: 'assets/images/slider-2/femme-vue-face-prenant-photos.jpg',
          //   alt: 'femme-vue-face-prenant-photos'
          // },
          // {
          //   url: 'assets/images/slider-2/close-up-ordinateur-portable-dessins-colores.jpg',
          //   alt: 'close-up-ordinateur-portable-dessins-colores'
          // },
    ]);


    const [startSliding1, setStartSliding1] = useState(false);
    const [startSliding2, setStartSliding2] = useState(false);
    const [recentCourses,setRecentCourses] = useState([]);
    const [sliders,setSliders] = useState([]);
  

    useEffect(() => {

      console.log('rrrrrrr' , slideImages1)


      axios(`${API_BASE_URL}/slider/getAllSliders`)
      .then((res) => res.data)
      .then(
          (result) => {   
               console.log('ssss',result)
               //setSliders(result)
               const leftImages = result.filter(image => image.position === 'left');
               const rightImages = result.filter(image => image.position === 'right');
               console.log('lllllfftttt',leftImages)
              if(leftImages.length>0) setSlideImages1(leftImages);
              if(rightImages.length>0) setSlideImages2(rightImages);
          
          },
          (error) => {
              console.log(error);
          }
      );

      axios(`${API_BASE_URL}/trainings-main`)
      .then((res) => res.data)
      .then(
          (result) => {   
               console.log('mmmmm',result)
               setRecentCourses(result)
          
          },
          (error) => {
              console.log(error);
          }
      );



        window.onload =() =>{
           setStartSliding2(true);
      
    }
    }, []);


    useEffect(() => {
    
        window.onload =() =>{
        setTimeout(() => {
        setStartSliding1(true);
      }, 2000); 
    }
  }, [startSliding2]);



    // if (!Array.isArray(slideImages1) || slideImages1.length <= 0) {
    //     return null;
    //   }
    //   if (!Array.isArray(slideImages2) || slideImages2.length <= 0) {
    //     return null;
    //   }
    

    // const [recentCourses,setRecentCourses]=useState([
    // {id:1,title:"Developpement",path:"assets/images/course1.png",start:"12 August",end:"28 August",rate:"4.5",sessionsCount:"25",sessionDuration:"6"},
    // {id:2,title:"Design graphique",path:"assets/images/course1.png",start:"12 August",end:"28 August",rate:"4.5",sessionsCount:"25",sessionDuration:"6"},
    // {id:3,title:"management",path:"assets/images/course1.png",start:"12 August",end:"28 August",rate:"4.5",sessionsCount:"25",sessionDuration:"6"},
    // {id:4,title:"soft skills",path:"assets/images/course1.png",start:"12 August",end:"28 August",rate:"4.5",sessionsCount:"25",sessionDuration:"6"}
    //                     ]);
    const [formateurs, setFormateurs] = useState([
        {id:1,path:"assets/images/formateur1.png",firstName:"ahmed",lastName:"ben selim",field:"developpeur full stack",users:"120",coursesCount:"36"},
        {id:1,path:"assets/images/formateur1.png",firstName:"ahmed",lastName:"ben selim",field:"developpeur full stack",users:"120",coursesCount:"36"},
        {id:1,path:"assets/images/formateur1.png",firstName:"ahmed",lastName:"ben selim",field:"developpeur full stack",users:"120",coursesCount:"36"},
        {id:1,path:"assets/images/formateur1.png",firstName:"ahmed",lastName:"ben selim",field:"developpeur full stack",users:"120",coursesCount:"36"}
])
const [partners,setPartners] = useState([
{id:1,path:"assets/images/logo-adn.svg",url:"https://www.adn-broadcast.com"},
{id:2,path:"assets/images/logo-dozane-studio.svg",url:"https://dozane-studio.com"},
{id:3,path:"assets/images/startup-act-Tunisie.png",url:"/#"},
{id:1,path:"assets/images/logo-adn.svg",url:"https://www.adn-broadcast.com"},
{id:2,path:"assets/images/logo-dozane-studio.svg",url:"https://dozane-studio.com"},
{id:3,path:"assets/images/startup-act-Tunisie.png",url:"/#"}
]);
    
    return (
        <>
            <div className="s_homehero">
                <div className="w_homeherotext homeFirstSection">
                    <div className="c_page firstCol"><h1 data-gsap-animate="true" className="h_xlarge">
                        {t("slogan_1_1")}
                        <br />{t("slogan_1_2")}</h1>
                        <p data-w-id="a17349a6-ebfb-ca74-ee37-2ec10ab66c8b"
                            className="p_heroparagraph cc-homeherop">{t("slogan_2")}</p>
                            <div className="registerLink" ><NavLink to="/create-account" >{t("Register_for_free")}</NavLink>
                            </div>
                    </div>
                    <div className='secondCol'>
      
                           <div className="slide-container slide-containerL">
       <Slider images={slideImages1} interval={4000} startDelay={0} /> 
    </div>
      


                    </div>
                    <div className='thirdCol'>
      <div className="slide-container slide-containerR">
       <Slider images={slideImages2} interval={4000} startDelay={2000} /> 
       </div>
      
    </div>
                   
                </div>

            </div>
            <div className='fullBanner fullBannerHome'><img src='/assets/images/home3.png' alt='gdtc home banner'/></div>
            <div className='coursesSection'>
                <div className='sectionTitle'><p>{t("Recent_courses")}</p></div>
                <div className="row itemsRow">
              {recentCourses &&
                recentCourses.map((formation) => {
                  return (
                    <CCol md="3" xs="6" key={formation.id}>
                      {<div className='cardsWraper'>
                      
                      <CourseCard
                          title={
                            formation.title && formation.title
                          }
                          // image={
                          //   formation.path
                          // }
                          path={formation.demo && formation.demo}
                          image={formation.imageTrainings && formation.imageTrainings.length>0? formation.imageTrainings[0].path : ''}
                               
                          url="/#"
                          start={formation.createdAt}
                          end={formation.end}
                          rate={formation.rate}
                          sessionsCount={formation.sessions.length}
                          sessionDuration={formation.sessionDuration}
                          medical={false}
                          id={formation.id}
                        />
{/*                       
                                              <TrainingCard
                                path={formation.demo && formation.demo}
                                image={formation.imageTrainings && formation.imageTrainings.length>0? formation.imageTrainings[0].path : ''}
                                title={formation.title}
                                formateur={formation.createdBy ? 
                                formation.createdBy.firstName&&formation.createdBy.firstName+" "+formation.createdBy.lastName
                                :''}
                                createdAt={formation.createdAt}
                                updatedAt={formation.updatedAt}
                                sessions={formation.sessions}
                                id={formation.id}
                                language={'English'}
                                medical={false}
                                >

                                </TrainingCard> */}

                        </div>
                      }
                    </CCol>
                  );
                })}
              {!recentCourses.length && (
                <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>
              )}
            </div>
            </div>
            {/* <div className='formateursSection'>
            <div className='sectionTitle'><p>Nos formateurs</p></div>
                <div className="row itemsRow formateursRow">
              {formateurs &&
                formateurs.map((element) => {
                  return (
                    <CCol md="3" xs="6" key={element.id}>
                      {<div className='cardsWraper'>
                        <FormateurCard
                          image={
                            element.path
                          }
                          url="/#"
                          firstName={element.firstName}
                          lastName={element.lastName}
                          field={element.field}
                          users={element.users}
                          coursesCount={element.coursesCount}
                        /></div>
                      }
                    </CCol>
                  );
                })}
              {!formateurs.length && (
                <div className="loadingGif">
                  <img src="/assets/icons/loading-bar.gif" />
                </div>
              )}
            </div>
            </div> */}
            <div className='partnersSection'>
            <div className='sectionTitle'><p> {t("Our_partners")} </p></div>
                <div className="row itemsRow">
            {partners &&
                partners.map((element) => {
                  return (
                <CCol md="2" xs="6">
                    <a href={element.url} >
                    <img src={element.path} alt={element.url} />
                    </a>
                </CCol>
                  );
                })}
                </div>
            </div>
            <div className='newsletterSection'>
             <div className='newsletterFrame'>
                <div className='newsletterInner'>
                <div className='newsletterRight'>
                  <h3>{t("Subscribe_our_newsletter")}</h3>
                  <p>{t("stay_up_to_date")} ...</p>
                  <div>
                  <input type='text'/>
                  <button>{t("Subscribe")}</button>
                  </div>
                </div>
                <div className='newsletterLeft'>
                  <img src='assets/images/newsletter.png' alt='gdtc newsletterimage' />
                </div>
                </div>
             </div>
            </div>
{/* <div className='backgroundBottom'></div>             */}

        </>
    )
}

export default HomePage

