import React from 'react'
import { useTranslation } from "react-i18next";
const Courses = () => {
    const { t } = useTranslation();
    return (
        <>
              <section className="presentation_page" >
                <p>
                Bienvenue dans l'univers dynamique de la formation proposée par GDTC (Global Domain Training Center). Notre plateforme de formation hybride révolutionne l'apprentissage en offrant une variété exceptionnelle de cours dans une multitude de domaines. Nos cours spécifiques et concentrés allient l'apprentissage en ligne à des stages pratiques, vous permettant d'acquérir des compétences essentielles et d'atteindre l'excellence professionnelle.
                </p>
                <strong>Une Diversité de Cours</strong>
                <p>
               
GDTC s'est engagé à répondre aux besoins uniques de nos apprenants en proposant une gamme étendue de cours dans différents domaines d'activité. Quel que soit votre secteur d'intérêt, notre catalogue de cours vous offre la possibilité de développer des compétences spécialisées qui feront la différence dans votre carrière.
                </p>
                <strong> Cours Spécifiques et Concentrés</strong>
                <p>
               
Nos cours spécifiques et concentrés sont soigneusement conçus pour cibler des compétences clés requises sur le marché du travail actuel. Plongez dans des programmes d'apprentissage ciblés qui vous permettront de maîtriser rapidement des domaines spécifiques et de devenir un expert reconnu.
                </p>
                <strong>La Puissance de l'Alternance</strong>
<p>

Ce qui distingue GDTC, c'est notre approche hybride qui combine intelligemment l'apprentissage en ligne avec des stages pratiques. Les cours en ligne vous offrent la flexibilité d'apprendre à votre rythme, tandis que les stages pratiques vous donnent l'opportunité de mettre en pratique ce que vous avez appris dans un environnement réel.
</p>
             <strong>Avantages de Nos Cours</strong>
             <ul>
             <li><strong>Expertise Pédagogique :</strong> Nos formateurs expérimentés et qualifiés vous guident à chaque étape, vous offrant un encadrement de qualité.
</li>
             <li><strong>Pratique Immédiate :</strong> Mettez en œuvre vos connaissances dans des contextes réels grâce à des stages pratiques qui renforcent votre compréhension.
</li>
             <li><strong>Adaptabilité :</strong> Notre approche hybride vous permet de suivre les cours en ligne à votre convenance, ce qui vous permet de concilier vos engagements personnels et professionnels.
</li>
             <li><strong>Certification Reconnue :</strong> À la fin de chaque cours, recevez une certification qui témoigne de vos compétences et de votre maîtrise.
</li>
             </ul>

             <strong>Comment Accéder à Nos Cours</strong>
             <p>        
L'accès à nos cours est simple et intuitif. Connectez-vous à votre compte GDTC, explorez notre catalogue et choisissez les cours qui correspondent à vos besoins et à vos objectifs. Commencez votre parcours d'apprentissage en ligne et préparez-vous à vous plonger dans des stages pratiques captivants.
             </p>
             
<p>GDTC vous offre bien plus que des cours originals. Explorez nos cours spécifiques et concentrés, et découvrez comment notre approche hybride unique peut propulser votre carrière vers de nouveaux sommets. Plongez dans l'apprentissage en ligne passionnant et pratique avec GDTC, et préparez-vous à devenir un professionnel accompli et compétent dans votre domaine de prédilection.</p>

              </section>
        </>
    )
}

export default Courses