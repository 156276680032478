import React from 'react'
import { useTranslation } from "react-i18next";
const AboutGMTC = () => {
    const { t } = useTranslation();
    return (
        <>

<section className="presentation_page" >
   <strong>Introduction</strong>
   <p>{t("about_GMTC1")}</p>
    <strong>{t("about_GMTC2")}</strong>
    <p>{t("about_GMTC3")}</p>
   <strong>{t("about_GMTC4")}</strong>
 <p>{t("about_GMTC5")}</p>
  <strong>{t("about_GMTC6")}</strong>
  <p>{t("about_GMTC7")}</p>
  <strong>{t("about_GMTC8")}</strong>
  <ul>
    <li><strong>{t("about_GMTC9")}</strong>{t("about_GMTC10")}</li>
    <li><strong>{t("about_GMTC11")}</strong>{t("about_GMTC12")}</li>
    <li><strong>{t("about_GMTC13")}</strong>{t("about_GMTC14")}</li>
  </ul>
  <strong>{t("about_GMTC15")}</strong>
<p>{t("about_GMTC16")}</p>
    <strong>Conclusion</strong>
    <p>{t("about_GMTC17")}</p>

    
    </section>

        </>
    )
}

export default AboutGMTC
