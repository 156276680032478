import React, { useEffect, useState , useRef } from "react";
import { API_BASE_URL, IMAGES_BASE_URL, consol } from "../../../config";
import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../../reducers/actions';
import { Link } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import apiClient from "src/components/apiClient";
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CButton,
  CSpinner

} from "@coreui/react";

const QcmTest = (props) => {

    const [error, setError] = useState(null);
    const [errorTest, setErrorTest] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [module, setModule] = useState(null);
    const [result, setResult] = useState(null);
    const [userAnswers, setUserAnswers] = useState({});
    const [userTests, setUserTests] = useState(null);
    const [userTestsIds, setUserTestsIds] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null); // assuming duration is in minutes
    const [showPopup, setShowPopup] = useState(false);
    const timerRef = useRef(null);
    const userAnswersRef = useRef(userAnswers);

    useEffect(() => {

        console.log('propsssss' , props)
        setModule(props.location.state)
        setTimeLeft(props.location.state.duration * 60)
        //setTimeLeft(10)
              }, []);

    useEffect(() => {
                userAnswersRef.current = userAnswers;
      }, [userAnswers]);  

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          calculateResult(); // calculate the result when time runs out
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, []);

      const calculateResult = async () => {
     
        setShowPopup(true);
       {
        setErrorTest('');

      try {
        setSubmitting(true)
        setResult(null)
        console.log('answrsssss : ', userAnswers)
        const moduleId = props.location.state.id;
        //const userId = localStorage.getItem('id')
        const response = await apiClient.post("/member-api/test/validate", {
          moduleId,
          answers: userAnswersRef.current
        });
        console.log('test resss',response)
        setResult(response.data);
        setSubmitting(false)
      } catch (error) {
        console.error("Error submitting answers", error);
        setSubmitting(false)
      }
       }
      };

      const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      };

    const handleAnswerChange = (questionId, answerIndex) => {
      setUserAnswers({
        ...userAnswers,
        [questionId]: answerIndex
      });
      consol(userAnswers)
    };
    
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      // Check if all questions are answered
      if (module.questions.some(q => userAnswers[q.id] === undefined)) {
        setErrorTest('Please answer all questions.');
        return;
      }
  
      setErrorTest('');

      try {
        setSubmitting(true)
        clearInterval(timerRef.current);
        setResult(null)
        const moduleId = module.id;
       // const userId = localStorage.getItem('id')
        const response = await apiClient.post("/member-api/test/validate", {
          moduleId,
          answers: userAnswers
        });
        console.log('test resss',response)
        setResult(response.data);
        setSubmitting(false)
      } catch (error) {
        console.error("Error submitting answers", error);
        setSubmitting(false)
      }
    };

    const getResultByModuleId = (moduleId) => {
      const resultObject = userTests.find(result => result.module.id === moduleId);
      return resultObject ? resultObject.result : null;
    };

    const getLetter = (index) => {
      return String.fromCharCode(65 + index);
    };

   
    
      return(
        <div className="takeCourseWrapper" >
      {module &&  <div className="contentCurrentModule"><br/>
<div className="contentVideo" style={{width:'75%' , margin:'auto' , position:'relative'}}>
<div className="sticky-timer">
        <p>Time left: {formatTime(timeLeft)}</p>
      </div>
<div className="contentVideoHeading">
<h3 
>{module?module.title:' '} </h3>
{ module && module.description && module.description.length>0 && <h5 
dangerouslySetInnerHTML={{ __html: module.description }}>
</h5>}
</div>


<>
<div className="contentVideoHeading">
          <br/><br/>

         
          <form className="moduleTestForm">
      {module.questions.map((question ,i) => (
        <div key={question.id} className="moduleTestFormDiv" >
          <p><strong>{i+1}</strong>. {question.text}</p>
          <div  className="moduleTestFormDivAns">
          {question.answers.map((answer, index) => (
            <div key={index} className="moduleTestFormDivAns1">
              <label>
                <input
                  type="radio"
                  name={`question-${question.id}`}
                  value={index}
                  checked={userAnswers[question.id] === index}
                  onChange={() => handleAnswerChange(question.id, index)}
                />
              <span>{getLetter(index)}.</span>  {answer.text}
              </label>
            </div>
          ))}
          </div>
        </div>
      ))}
      {errorTest && <p style={{ color: 'red' , textAlign:'center' }}>{errorTest}</p>}

      {userTestsIds && userTestsIds.includes(module.id) ? 
       <>
       <div className={`testResults ${getResultByModuleId(module.id)>49? 'testPassed' : 'testFailed'}`}>
          <p>You have already did this test with a : {getResultByModuleId(module.id)} % result</p>
        </div>
       <button className="submitForm" onClick={handleSubmit} disabled >
         Submit</button>
         </> 
      : 
      <button className="submitForm" onClick={handleSubmit} disabled={submitting || result} >
      {submitting &&   <CSpinner color="default" size="sm" />} Submit</button>
      }

      {result && (
        <div className={`testResults ${result.percentage>49? 'testPassed' : 'testFailed'}`}>
          <p>Total Correct Answers: {result.correctAnswers}</p>
          <p>Percentage: {result.percentage}%</p>
          <p> You will receive an email about the next step</p>
        </div>
      )}

    </form>

    <CModal alignment="center" visible={showPopup} onClose={() => setShowPopup(false)}>
          <CModalHeader>
            <CModalTitle>
              Alerte
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <>
              <br />
              <div className="popup">
          <h2>Time's up!</h2>
          {submitting &&  <div><strong> submitting answers </strong><CSpinner color="default" size="sm" /> </div>}
          {result && (
        <div className={`testResults ${result.percentage>49? 'testPassed' : 'testFailed'}`}>
          <p>Total Correct Answers: {result.correctAnswers}</p>
          <p>Percentage: {result.percentage}%</p>
          <p>You will receive an email about the next step</p>
        </div>
      )}
        </div>
              <br />
            </>
          </CModalBody>
          <CModalFooter>

            {/* <CButton color="secondary" onClick={() => setShowPopup(false)}> OK </CButton> */}

          </CModalFooter>
        </CModal>

          </div>
</>
 
</div>
<br/>
</div> }
        </div>
      )
}
export default QcmTest