import React , { useState } from 'react'
import Login from '../member/Login'
import apiClient from 'src/components/apiClient'
import {
CSpinner,
} from '@coreui/react'
import { useTranslation } from "react-i18next";

const Checkout = () => {
  
  const { t } = useTranslation();
  const [error, setError] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleCheckOut = () =>  {

if (localStorage.getItem('auth')) {
  console.log('checking')
setSubmitted(true)
   apiClient.get("/member-api/place-order")
      .then((response) => {
          console.log("orderrr");
          console.log(response);
          setSubmitted(false)
          setSuccess(true)
          setTimeout(() => {  
            window.location.href = `/my-profile` 
         }, 3000);
      })
      .catch((response) => {
          console.log(response);
          setError(true)
          setSubmitted(false)
      });
}
  }
    return (
        <>
             {localStorage.getItem("roles") === "ROLE_PARTICIPANT" ? (
              <div className="container">
                <main className="l-main">
                <div className="text-center w_commercial_alternating_cards cc_text_group_7" >

                    <div className="l-section l-section--archive">
                      <br/>
                  <div className="col-md-12 text-center">
                                <button onClick={handleCheckOut} className="btn_black w-button btn-block checkoutButton" 
                                 disabled={submitted} style={{float:'none'}} >
                                {submitted && <CSpinner color="default" size="sm" />} Checkout</button>
                           {success && <span> {t("order_placed_successfully")} </span>}
                            </div>
                            <br/> <br /> <br />
                   </div> </div> </main> </div>
                ): (
                  <Login></Login>
                )}
        </>
    )
}

export default Checkout
