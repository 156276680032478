import React from 'react'
import { useTranslation } from "react-i18next";
const Staff = () => {
    const { t } = useTranslation();
    return (
        <>
              <section className="presentation_page" >

    <strong>{t("staff_1")}</strong>
    <p>{t("staff_2")}</p>



    
    </section>
        </>
    )
}

export default Staff
