import React from 'react'
import { useTranslation } from "react-i18next";
const Philosophy = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="w_commercial_alternated_group cc_group1" >
        <div className="w_commercial_alternating_cards cc_text_group_7" >
            <div className="w-layout-grid " id="w-node-e8bf02f74c29-3a368f7a">
                <img src={"/assets/images/gdtc-tunisia-training-philosophy.jpeg"} id="w-node-e8bf02f74c29-3a368f7a"/>
                <div className="w_commercial_alternating_cards cc_text_group_1" id="w-node-e8bf02f74c29-3a368f7a">
                    <h2 className="h2_alternating_card" data-gsap-animate="true">{t("philosophy_1")}</h2>
                    <p>{t("philosophy_2")}</p>
                    <strong>{t("philosophy_3")}</strong>
                    <p>{t("philosophy_4")}</p>
                    <strong>{t("philosophy_5")}</strong>
                    <ul>
                    <li><strong>{t("philosophy_6")}</strong>{t("philosophy_7")}</li>
                    <li><strong>{t("philosophy_8")}</strong>{t("philosophy_9")}</li>
                    <li><strong>{t("philosophy_10")}</strong>{t("philosophy_11")}</li>
                    </ul>
                    <strong>{t("philosophy_12")}</strong>
                    <p>{t("philosophy_13")}</p>
                    <p>{t("philosophy_14")}</p>

                </div>
            </div>
        </div>
    </section>
        </>
    )
}

export default Philosophy
